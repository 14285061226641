<template>
  <v-flex xs12>
    <v-flex xs12>
      <v-text-field :label="$t('_name')" v-model="name"></v-text-field>
    </v-flex>
    <v-flex xs12>
      <v-text-field :label="$t('_description')" v-model="description"></v-text-field>
    </v-flex>
    <v-flex xs12 sm12 md6>
      <v-btn @click="save" :disabled="!details.isValid || savingWallet" color="primary text-capitalize">{{
        $t('_save')
      }}</v-btn>
    </v-flex>
  </v-flex>
</template>

<script>
import gql from 'graphql-tag';

export default {
  props: ['details', 'walletType', 'subsidiaryId', 'walletRoleId'],
  data() {
    return {
      name: '',
      description: '',
      savingWallet: false,
    };
  },
  watch: {
    name() {
      this.checkFormValid();
    },
    description() {
      this.checkFormValid();
    },
  },
  methods: {
    checkFormValid() {
      this.details.isValid = this.name.trim().length > 0;
    },
    resetForm() {
      this.name = '';
      this.description = '';
      this.savingWallet = false;
      this.walletRole = null;
    },
    async save() {
      this.savingWallet = true;
      const orgId = this.$store.state.currentOrg.id;
      const prems = [
        {
          userId: this.$store.state.user.id,
          role: 4,
        },
      ];

      const manual = {
        description: this.description,
      };
      const wallet = {
        name: this.name,
        type: this.walletType,
        subsidiaryId: this.subsidiaryId,
        walletRoleId: this.walletRoleId,
        manual,
      };

      const variables = {
        orgId,
        wallet,
        prems,
      };

      await this.$apollo.mutate({
        mutation: gql`
          mutation ($orgId: ID!, $wallet: WalletInput!, $prems: [WalletPermissionInput]!) {
            createWallet(orgId: $orgId, wallet: $wallet, prems: $prems) {
              id
            }
          }
        `,
        variables,
      });

      this.savingWallet = false;
      this.resetForm();
      this.$root.$emit('refresh-wallets');
      this.$emit('wallet-saved');
    },
  },
};
</script>
