<template>
  <v-flex xs12>
    <v-btn @click="connect">Connect</v-btn>
  </v-flex>
</template>

<script>
import 'firebase/firestore';

import bncOnboard from 'bnc-onboard';
import gql from 'graphql-tag';
import Web3 from 'web3';

export default {
  props: ['details', 'walletRoleId'],
  data() {
    return {
      // addressTypes: ["Simple", "XPUB"],
      coins: ['ETH'],
      deviceTypes: ['Trezor'],
      newType: '',
      addressDetails: {},
      searchPath: '',
      coin: null,
      isValid: false,
      addresses: null,
      name: '',
      deviceType: '',
      onboard: null,
    };
  },
  computed: {
    addressTypes() {
      if (this.newCoin === 'BTC') {
        return ['Simple', 'XPUB'];
      } else if (this.newCoin === '') {
        return [];
      } else {
        return ['Simple'];
      }
    },
  },
  mounted() {
    let web3;

    const onboard = bncOnboard({
      dappId: '774494f9-dd15-4079-bd83-43151cdfa005', // [String] The API key created by step one above
      networkId: 1, // [Integer] The Ethereum network ID your Dapp uses.
      subscriptions: {
        wallet: (wallet) => {
          console.log(wallet);
          web3 = new Web3(wallet.provider);
          console.log(web3.eth.accounts);
          web3.eth.getAccounts().then((m) => console.log('Accounts', m));
        },
      },
    });
    this.onboard = onboard;
  },
  methods: {
    async connect() {
      await this.onboard.walletReset();
      await this.onboard.walletSelect();
      await this.onboard.walletCheck();
    },
    async save() {
      const orgId = this.$store.state.currentOrg.id;

      const prems = [
        {
          userId: this.$store.state.user.id,
          role: 4,
        },
      ];

      /*
        input HardwareWallet {
            coin: String
            deviceName: String!
            path: String!
            address: String
            hd: String
        }
       */
      const hw = {
        coin: this.coin,
        path: this.addressDetails.path,
        address: this.addressDetails.address,
        deviceType: this.deviceType,
      };

      const wallet = {
        type: 'hardware',
        name: this.name,
        hardware: hw,
        walletRoleId: this.walletRoleId,
      };

      const vars = {
        wallet,
        orgId,
        prems,
      };

      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($orgId: ID!, $wallet: WalletInput!, $prems: [WalletPermissionInput]!) {
              createWallet(orgId: $orgId, wallet: $wallet, prems: $prems) {
                id
              }
            }
          `,
          // Parameters
          variables: vars,
        })
        .then(() => {
          console.log('added wallet');
          this.resetWallet();
          this.$root.$emit('refresh-wallets');
          this.$emit('wallet-saved');
        });
    },
    resetWallet() {
      this.deviceName = '';
      this.path = '';
      this.coin = null;
      this.addressDetails = null;
    },
  },
};
</script>
<style></style>
