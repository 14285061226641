var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-flex-col"},[_c('div',{staticClass:"tw-flex tw-flex-row tw-flex-wrap tw-m-4"},[_c('div',{staticClass:"tw-w-1/4"},[_c('ui-text-edit',{staticClass:"tw-text-normal",attrs:{"label":'Search by wallet name, id or address:'},model:{value:(_vm.searchWallet),callback:function ($$v) {_vm.searchWallet=$$v},expression:"searchWallet"}})],1),_c('div',{staticClass:"tw-flex tw-ml-auto tw-items-center tw-p-3"},[_c('ui-checkbox',{attrs:{"label":"Show Disabled Wallets"},on:{"input":function($event){_vm.page = 1}},model:{value:(_vm.showDisabledWallets),callback:function ($$v) {_vm.showDisabledWallets=$$v},expression:"showDisabledWallets"}})],1)]),_c('div',{staticClass:"tw-border tw-border-gray-300"},[_c('ui-data-table',{attrs:{"headers":_vm.headers,"items":_vm.pageWallets,"isLoading":!!_vm.isLoading,"no-data-message":"There are no wallets to display.","show-action-column":"","fixed-table":"","resizeable-columns":"","actionColumnWidth":"93px","align-top":""},on:{"sort":_vm.onSort,"row-hovered":_vm.onRowHovered},scopedSlots:_vm._u([{key:"td-name",fn:function(ref){
var wallet = ref.item;
return [_c('td',{},[(!_vm.short)?_c('span',[_c('ui-truncate-text',{attrs:{"position":"right","value":wallet.name}})],1):_c('span',[_c('ui-truncate-text',{attrs:{"position":"right","value":wallet.name}})],1),(!_vm.short)?_c('p',{staticClass:"tw-break-all"},[_c('ui-truncate-text',{attrs:{"position":"right","value":wallet.description}})],1):_vm._e()])]}},{key:"td-address",fn:function(ref){
var wallet = ref.item;
return [_c('td',{},[(wallet.type !== 999)?_vm._l((wallet.addresses),function(item){return _c('div',{key:item},[_c('ui-truncate-text',{attrs:{"copyable":"","position":"right","value":item}})],1)}):_c('div',{staticClass:"tw-truncate"})],2)]}},{key:"td-token",fn:function(ref){
var wallet = ref.item;
return [_c('td',{staticClass:"tw-p-0"},[(wallet.balance && wallet.type !== 999)?_c('div',{ref:wallet.id + '-token',staticClass:"tw-overflow-auto no-scroll tw-transition-all tw-duration-500",class:{ 'tw-max-h-40': _vm.expandedWallet !== wallet.id }},[_vm._l((wallet.balance.balances),function(item){return _c('div',{key:item.coin + 'token',staticClass:"tw-truncate tw-px-2 tw-border-b tw-border-gray-300 last:tw-border-b-0"},[_c('span',[_vm._v(_vm._s(_vm.getSymbolForCoin(item.coin)))])])}),(_vm.hasFiatValue(wallet))?_c('div',{staticClass:"tw-font-semibold tw-px-2 tw-pt-2 tw-truncate"},[_c('span',[_vm._v("Total")])]):_vm._e()],2):_c('div',{staticClass:"tw-truncate"})])]}},{key:"td-amount",fn:function(ref){
var wallet = ref.item;
return [_c('td',{staticClass:"tw-p-0 tw-relative"},[(wallet.balance && wallet.type !== 999)?[_c('div',{ref:wallet.id + '-amount',staticClass:"tw-overflow-auto tw-transition-all tw-duration-500",class:{ 'tw-max-h-40': _vm.expandedWallet !== wallet.id, 'no-scroll': _vm.hasFiatValue(wallet) }},[_vm._l((wallet.balance.balances),function(item){return _c('div',{key:item.coin + 'amount',staticClass:"tw-truncate tw-px-2 tw-border-b tw-border-gray-300 last:tw-border-b-0"},[_c('span',[_vm._v(_vm._s(item.displayValue))])])}),(_vm.hasFiatValue(wallet))?_c('div',{staticClass:"tw-px-2 tw-pt-2"},[_c('span',{staticClass:"tw-opacity-0"},[_vm._v("Total")])]):_vm._e()],2),_c('transition',{attrs:{"name":"fade"}},[(_vm.hoveredWallet && _vm.hoveredWallet.id === wallet.id && _vm.showExpand(_vm.hoveredWallet.id || ''))?_c('div',{staticClass:"tw-absolute tw-left-0 tw-right-0 tw-mx-auto tw-w-20 tw-z-20 tw-overflow-hidden"},[_c('button',{staticClass:"tw-transform tw-rotate-180 tw-w-full tw-h-8 tw-rounded-t-md tw-bg-neutral-200 tw-flex tw-items-center tw-justify-center",attrs:{"type":"button"},on:{"click":function($event){return _vm.expand(wallet.id)}}},[_c('fa',{staticClass:"tw-text-neutral-300 tw-text-xl tw-transform",class:{ 'tw-rotate-180': _vm.expandedWallet === wallet.id },attrs:{"icon":"fa-solid fa-arrow-up"}})],1)]):_vm._e()])]:_c('div',{staticClass:"tw-truncate"})],2)]}},{key:"td-fiatValue",fn:function(ref){
var wallet = ref.item;
return [_c('td',{staticClass:"tw-p-0"},[(wallet.balance && _vm.hasFiatValue(wallet) && wallet.type !== 999)?_c('div',{ref:wallet.id + '-fiat',staticClass:"tw-overflow-auto tw-transition-all tw-duration-500",class:{ 'tw-max-h-40': _vm.expandedWallet !== wallet.id }},[_vm._l((wallet.balance.balances),function(item){return _c('div',{key:item.coin + 'fiat',staticClass:"tw-truncate tw-px-2 tw-border-b tw-border-gray-300 last:tw-border-b-0"},[_c('span',[_vm._v(_vm._s(_vm.getSymbolForCurrency(item.fiatValue.currency))+" ")]),_vm._v(" "+_vm._s(item.fiatValue.displayValue)+" ")])}),(wallet.balance && wallet.balance.balances)?_c('div',{staticClass:"tw-truncate tw-font-semibold tw-pt-2 tw-px-2"},[_c('span',[_vm._v(_vm._s(_vm.getSymbolForCurrency(wallet.balance.balances[0].fiatValue.currency)))]),_vm._v(" "+_vm._s(_vm.numFormat(_vm.totalFiatValue(wallet) || 0, { decimalPlaces: 4 }))+" ")]):_vm._e()],2):_c('div',{staticClass:"tw-truncate"})])]}},{key:"td-providers",fn:function(ref){
var wallet = ref.item;
return [_c('td',{staticClass:"tw-p-0"},[_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.connectionIdToProviderMap[wallet.exchangeConnectionId] || _vm.connectionIdToProviderMap[wallet.connectionId]))])])])]}},{key:"td-subsidiaryId",fn:function(ref){
var wallet = ref.item;
return [_c('td',{staticClass:"tw-p-0"},[(wallet.subsidiaryId)?_c('span',[_c('ui-truncate-text',{attrs:{"position":"right","value":_vm.getSubName(wallet.subsidiaryId)}})],1):_vm._e()])]}},{key:"td-balance",fn:function(ref){
var wallet = ref.item;
return [_c('td',{},[(wallet.type !== 999 && wallet.type !== 10)?_c('div',{staticClass:"tw-truncate",class:{
              'tw-text-green-700': _vm.balanceCheckStatus(wallet).state === 'success',
              'tw-text-red-700': _vm.balanceCheckStatus(wallet).state === 'error',
              'tw-text-tertiary-300': _vm.balanceCheckStatus(wallet).state === 'warning',
            }},[_c('fa',{attrs:{"icon":_vm.balanceCheckStatus(wallet).icon}}),_vm._v(" "+_vm._s(_vm.balanceCheckStatus(wallet).message)+" "),_vm._l((_vm.calcDeltaByTicker(wallet)),function(result,i){return _c('div',{key:wallet.id + 'checkDelta' + i,staticClass:"tw-truncate"},[(result[1] !== 0)?_c('span',[_vm._v(" "+_vm._s(result[0])+" delta : "),_c('span',{class:{
                    'tw-text-green-700': result[1] === 0,
                    'tw-text-tertiary-300': result[1] !== 0,
                  }},[_vm._v(_vm._s(_vm.numFormat(result[1], { decimalPlaces: 4 })))])]):_vm._e()])})],2):_c('div',{staticClass:"tw-truncate"},[(wallet.type === 10)?_c('span',[_vm._v(" N/A ")]):_vm._e()])])]}},{key:"td-status",fn:function(ref){
                  var wallet = ref.item;
return [_c('td',{},[(wallet.type !== 999 && wallet.type !== 10)?_c('div',{staticClass:"tw-truncate",class:{
              'tw-text-green-700': _vm.syncStatus(wallet).state === 'success',
              'tw-text-red-700': _vm.syncStatus(wallet).state === 'error',
              'tw-text-tertiary-300': _vm.syncStatus(wallet).state === 'warning',
            }},[_c('fa',{attrs:{"icon":_vm.syncStatus(wallet).icon}}),_vm._v(" "+_vm._s(_vm.syncStatus(wallet).message)+" ")],1):_c('div',{staticClass:"tw-truncate"},[(wallet.type === 10)?_c('span',[_vm._v(" N/A ")]):_vm._e()])])]}},{key:"td-lastSync",fn:function(ref){
            var wallet = ref.item;
return [_c('td',{},[(wallet.type !== 999 && wallet.type !== 10)?_c('div',{staticClass:"tw-truncate"},[_vm._v(" "+_vm._s(_vm.lastSyncTime(wallet))+" ")]):_c('div',{staticClass:"tw-truncate"},[(wallet.type === 10)?_c('span',[_vm._v(" N/A ")]):_vm._e()])])]}},{key:"td-syncToggle",fn:function(ref){
            var wallet = ref.item;
return [_c('td',{staticClass:"tw-text-center"},[(wallet.type !== 999 && wallet.type !== 10)?_c('div',{staticClass:"tw-flex tw-items-center tw-gap-2 tw-text-gray-700 tw-font-medium"},[_c('ui-toggle',{class:{ 'tw-opacity-50': !wallet.isSyncEnabledSystem },attrs:{"disabled":!wallet.isSyncEnabledSystem},on:{"input":function($event){return _vm.toggleSync(wallet, $event)}},model:{value:(wallet.isSyncEnabledUser),callback:function ($$v) {_vm.$set(wallet, "isSyncEnabledUser", $$v)},expression:"wallet.isSyncEnabledUser"}}),(wallet.isSyncEnabledUser)?_c('span',{class:{
                'tw-text-neutral-500': wallet.isSyncEnabledUser,
                'tw-text-gray-400': !wallet.isSyncEnabledUser,
                'tw-opacity-50': !wallet.isSyncEnabledSystem,
              }},[_vm._v("Enabled")]):_c('span',{class:{
                'tw-text-netural-500': !wallet.isSyncEnabledUser,
                'tw-text-gray-400': wallet.isSyncEnabledUser,
                'tw-opacity-50': !wallet.isSyncEnabledSystem,
              }},[_vm._v("Disabled")]),(!wallet.isSyncEnabledSystem)?_c('span',[_c('ui-tooltip',{attrs:{"position":"top","text":"Syncing Disabled by System"}},[_c('fa',{attrs:{"icon":"exclamation-triangle"}})],1)],1):_vm._e()],1):_c('div',{staticClass:"tw-truncate"})])]}},{key:"td-walletRoleId",fn:function(ref){
              var wallet = ref.item;
return [_vm._v(" "+_vm._s(wallet.walletRole)+" ")]}},{key:"td-action",fn:function(ref){
              var wallet = ref.item;
return [_c('td',{staticClass:"tw-text-right tw-whitespace-nowrap tw-py-3 tw-my-0"},[(wallet.type !== 999)?[(wallet.type === 2 || wallet.type === 5)?_c('div',[_c('receive',{attrs:{"wallet-id":wallet.id,"wallet":wallet,"enabled-coins":wallet.enabledCoins}}),_c('send',{attrs:{"wallet":wallet,"wallet-id":wallet.id,"enabled-coins":wallet.enabledCoins}})],1):_vm._e(),_c('ui-button',{attrs:{"color":"white"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.showWalletDetails(wallet)}}},[_vm._v("Details")])]:_c('div',{staticClass:"tw-truncate"})],2)]}}])})],1),_c('ui-pagination',{attrs:{"perPageControl":"","total":_vm.walletItems.length,"itemsPerPage":_vm.itemsPerPage},on:{"changeItemsPerPage":_vm.onItemsPerPageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),(_vm.viewDialog)?_c('ui-modal',{attrs:{"modalTitle":'Wallet Details'},on:{"close":_vm.closeDetails}},[_c('div',{style:({ width: _vm.dialogWidth })},[_c('view-wallet',{attrs:{"wallet":_vm.viewWallet,"close":_vm.closeDetails,"showDefaultCategorization":_vm.checkFeatureFlag('default-categorization-fields'),"connections":_vm.connections}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }