<template>
  <v-flex xs12>
    <v-flex xs12>
      <v-text-field :label="$t('_name')" v-model="name"></v-text-field>
    </v-flex>
    <v-flex xs12>
      <v-text-field :label="$tc('_address', 1)" v-model="address"></v-text-field>
    </v-flex>
    <v-flex xs12 sm12 md6 v-if="canRollup">
      <v-layout row wrap>
        <v-flex xs12>
          <v-checkbox v-model="rollup" label="Rollup Transactions">Rollup</v-checkbox>
        </v-flex>
        <v-flex xs6 v-if="rollup">
          <v-select v-model="rollupSelector" :items="rollupSelectors" label="Type"></v-select>
        </v-flex>
        <v-flex xs6 v-if="rollup">
          <v-select v-model="rollupPeriod" :items="rollupPeriods" label="Period"></v-select>
        </v-flex>
      </v-layout>
    </v-flex>

    <v-flex xs7>
      <validation-summary ref="walletValidation" :validation-data="validationData" wallet-type="walletType" />
    </v-flex>

    <v-flex xs12 sm12 md6>
      <v-btn
        @click="validate"
        :disabled="!canValidate"
        :hidden="!validationEnabled"
        :loading="isValidating"
        color="primary text-capitalize"
        >{{ $t('_validate') }}</v-btn
      >

      <v-btn @click="save" :disabled="!canValidate || !canSave || savingWallet" color="primary text-capitalize">{{
        $t('_save')
      }}</v-btn>
    </v-flex>
  </v-flex>
</template>

<script>
import gql from 'graphql-tag';

import ValidationSummary from '@/components/validation/WalletValidationSummary';

export default {
  components: { ValidationSummary },
  props: ['details', 'walletType', 'subsidiaryId', 'walletRoleId'],
  data() {
    return {
      name: '',
      description: '',
      address: '',
      savingWallet: false,
      rollup: false,
      rollupPeriod: '',
      rollupSelector: '',
      rollupSelectors: ['revenue', 'fee', 'custom'],
      rollupPeriods: ['hour'], // 1d
      isValidating: false,
      validationData: null,
      validationError: undefined,
    };
  },
  watch: {
    name() {
      this.checkFormValid();
    },
    description() {
      this.checkFormValid();
    },
    address() {
      this.checkFormValid();
    },
  },
  computed: {
    validationEnabled() {
      const s = new Set(['celo', 'optimism']); // add more blockchain walletType to this list as needed
      return s.has(this.walletType);
    },
    canValidate() {
      const condition = Boolean(this.address);
      return condition;
    },
    canSave() {
      const condition = Boolean(this.details.isValid || this.savingWallet);
      return condition;
    },
    canRollup() {
      const s = new Set(['polygon', 'solana', 'optimism']);
      return s.has(this.walletType) && this.checkFeatureFlag('rollups', this.features);
    },
  },
  methods: {
    checkFormValid() {
      this.details.isValid =
        this.name.trim().length > 0 && this.address.indexOf(' ') === -1 && this.address.trim().length > 40;
    },
    resetForm() {
      this.name = '';
      this.address = '';
      this.savingWallet = false;
      this.isValidating = false;
    },
    async validate() {
      this.isValidating = true;
      const vars = this.getCreateVars();
      const validation = await this.$refs.walletValidation.validate(vars.wallet);
      this.details.isValid = validation.success;
      this.isValidating = false;
    },
    getCreateVars() {
      const orgId = this.$store.state.currentOrg.id;
      const prems = [
        {
          userId: this.$store.state.user.id,
          role: 4,
        },
      ];
      let rollupConfig;
      if (this.rollup) {
        if (this.rollupSelector === '' || this.rollupPeriod === '') {
          this.showErrorSnackbar('If setting rollups, must select type and period');
          return;
        }

        rollupConfig = {
          rollupPeriod: this.rollupPeriod,
          rollupSelector: this.rollupSelector,
        };
      }
      const addressBasedBlockchain = {
        address: this.address,
        rollupConfig,
      };
      const wallet = {
        name: this.name,
        type: this.walletType,
        subsidiaryId: this.subsidiaryId,
        walletRoleId: this.walletRoleId,
        addressBasedBlockchain,
      };
      const variables = {
        orgId,
        wallet,
        prems,
      };

      return variables;
    },
    async save() {
      this.savingWallet = true;
      const variables = this.getCreateVars();

      await this.$apollo.mutate({
        mutation: gql`
          mutation ($orgId: ID!, $wallet: WalletInput!, $prems: [WalletPermissionInput]!) {
            createWallet(orgId: $orgId, wallet: $wallet, prems: $prems) {
              id
            }
          }
        `,
        variables,
      });
      this.savingWallet = false;
      this.resetForm();
      this.$root.$emit('refresh-wallets');
      this.$emit('wallet-saved');
    },
  },
};
</script>
