































import gql from 'graphql-tag';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import type { Amount, Connection, Wallet } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiToggle from '@/components/ui/UiToggle.vue';
import CreateWalletNew from '@/components/wallets/CreateWalletNew.vue';
import WalletListNew from '@/components/wallets/WalletListNew.vue';

@Component({
  components: {
    UiButton,
    CreateWalletNew,
    WalletListNew,
    UiToggle,
  },
})
export default class WalletsNew extends BaseVue {
  @Prop({ default: [] })
  connections!: Connection[];

  public isCreatingWallet = false;

  public isNew = true;

  public toggleUi(val: boolean) {
    this.$emit('toggleUi', val);
    localStorage.setItem('walletsUi', val ? 'new' : 'old');
  }

  public get features() {
    return this.$store.getters.features;
  }

  public onCreateWallet() {
    this.isCreatingWallet = true;
  }

  public onBackToList() {
    this.isCreatingWallet = false;
  }

  public backFromCreateWallet(refresh: boolean) {
    this.isCreatingWallet = false;
    (this.$refs.list as WalletListNew)?.refresh();
  }
}
