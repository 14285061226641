<template>
  <v-flex xs12>
    <v-flex xs12>
      <v-text-field :label="$t('_name')" v-model="name"></v-text-field>
    </v-flex>
    <v-flex xs12>
      <v-text-field :label="$t('_affiliate')" v-model="affiliateId"></v-text-field>
    </v-flex>
    <v-flex xs12>
      <v-text-field :label="$t('_submitter')" v-model="submitterId"></v-text-field>
    </v-flex>
    <v-flex xs12>
      <v-text-field :label="$t('_description')" v-model="description"></v-text-field>
    </v-flex>

    <v-flex xs12 sm12 md6 v-if="canRollup">
      <v-layout row wrap>
        <v-flex xs12>
          <v-checkbox v-model="rollup" label="Rollup Transactions">Rollup</v-checkbox>
        </v-flex>
        <v-flex xs6 v-if="rollup">
          <v-select v-model="rollupSelector" :items="rollupSelectors" label="Type"></v-select>
        </v-flex>
        <v-flex xs6 v-if="rollup">
          <v-select v-model="rollupPeriod" :items="rollupPeriods" label="Period"></v-select>
        </v-flex>
      </v-layout>
    </v-flex>

    <v-flex xs12 sm12 md6>
      <v-btn @click="save" :disabled="!details.isValid || savingWallet" color="primary text-capitalize">{{
        $t('_save')
      }}</v-btn>
    </v-flex>
  </v-flex>
</template>

<script>
import gql from 'graphql-tag';

export default {
  props: ['details', 'walletType', 'networkId'],
  data() {
    return {
      name: '',
      description: '',
      affiliateId: undefined,
      submitterId: undefined,
      savingWallet: false,
      rollup: false,
      rollupPeriod: '',
      rollupSelector: '',
      rollupSelectors: ['revenue', 'fee'],
      rollupPeriods: ['hour'], // 1d
    };
  },
  watch: {
    name() {
      this.checkFormValid();
    },
    description() {
      this.checkFormValid();
    },
  },
  computed: {
    canRollup() {
      const s = new Set(['lrc']);
      return s.has(this.networkId) && this.checkFeatureFlag('rollups', this.features);
    },
  },
  methods: {
    checkFormValid() {
      this.details.isValid = this.name.trim().length > 0;
    },
    resetForm() {
      this.name = '';
      this.description = '';
      this.affiliateId = undefined;
      this.submitterId = undefined;
      this.savingWallet = false;
    },
    async save() {
      this.savingWallet = true;
      const orgId = this.$store.state.currentOrg.id;
      const prems = [
        {
          userId: this.$store.state.user.id,
          role: 4,
        },
      ];

      let rollupConfig;
      if (this.rollup) {
        if (this.rollupSelector === '' || this.rollupPeriod === '') {
          this.showErrorSnackbar('If setting rollups, must select type and period');
          return;
        }

        rollupConfig = {
          rollupPeriod: this.rollupPeriod,
          rollupSelector: this.rollupSelector,
        };
      }

      const lrcMarketplace = {
        affiliateId: Number(this.affiliateId),
        submitterId: Number(this.submitterId),
        description: this.description,
        rollupConfig,
      };
      const wallet = {
        name: this.name,
        type: this.walletType,
        lrcMarketplace,
      };

      const variables = {
        orgId,
        wallet,
        prems,
      };

      await this.$apollo.mutate({
        mutation: gql`
          mutation ($orgId: ID!, $wallet: WalletInput!, $prems: [WalletPermissionInput]!) {
            createWallet(orgId: $orgId, wallet: $wallet, prems: $prems) {
              id
            }
          }
        `,
        variables,
      });
      this.savingWallet = false;
      this.resetForm();
      this.$root.$emit('refresh-wallets');
      this.$emit('wallet-saved');
    },
  },
};
</script>
