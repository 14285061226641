<template>
  <v-flex xs12>
    <v-flex xs12>
      <v-text-field :label="$t('_name')" v-model="name"></v-text-field>
    </v-flex>
    <v-flex xs12>
      <v-text-field :label="$tc('_address', 1)" v-model="address"></v-text-field>
    </v-flex>

    <v-flex xs12>
      <v-layout row wrap>
        <v-flex xs12 sm12 md6 v-if="canRollup">
          <v-layout row wrap>
            <v-flex xs12>
              <v-checkbox v-model="rollup" label="Rollup Transactions">Rollup</v-checkbox>
            </v-flex>
            <v-flex xs6 v-if="rollup">
              <v-select v-model="rollupSelector" :items="rollupSelectors" label="Type">CustomRollup</v-select>
            </v-flex>
            <v-flex xs6 v-if="rollup">
              <v-select v-model="rollupPeriod" :items="rollupPeriods" label="Period"></v-select>
            </v-flex>
            <v-flex xs6 v-if="customRollup">
              <v-checkbox v-model="rollupRevenue" label="Rollup Revenue">RollupRevenue</v-checkbox>
              <v-checkbox v-model="rollupTokenRevenue" label="Rollup Token Revenue">RollupTokenRevenue</v-checkbox>
              <v-checkbox v-model="rollupFee" label="Rollup Fees">RollupFees</v-checkbox>
              <v-checkbox v-model="rollupRemittance" label="Rollup Remittances">RollupRemittances</v-checkbox>
              <v-checkbox v-model="rollupTokenRemittance" label="Rollup Token Remittances"
                >RollupTokenRemittances</v-checkbox
              >
              <v-checkbox v-model="rollupAll" label="Rollup All Leftover Transactions">RollupAll</v-checkbox>
            </v-flex>
            <v-flex xs6 v-if="customRollup">
              <v-checkbox v-model="seperateByAccount" label="Split Rollups by Account">RollupRevenue</v-checkbox>
              <v-btn @click="addAccount" color="primary text-capitalize">{{ $t('_addAccount') }}</v-btn>
              <div v-for="(account, index) in accountCheck" label="Involved Accounts" :key="index">
                <v-text-field v-model="accountCheck[index]" />
              </div>
              <v-btn @click="addLogSelector" color="primary text-capitalize">{{ $t('_addLogSelector') }}</v-btn>
              <div v-for="(logLine, index) in logSelector" label="Involved Accounts" :key="index">
                <v-text-field v-model="logSelector[index]" />
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm12 md6 v-if="isImmutableXConfig">
          <v-layout row wrap>
            <v-flex xs12>
              <v-radio-group v-model="immutableXConfig" row>
                <v-radio label="Normal" value="normal"></v-radio>
                <v-radio label="Ecosystem" value="ecosystem"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-flex>

        <!-- manage wallet flags -->
        <div v-if="hasTrackingMode" class="tw-flex tw-flex-col tw-gap-2 tw-w-1/2">
          <label class="v-label theme--light tw-text-md tw-w-full">{{ $tc('_walletFeatures') }}:</label>
          <div v-if="hasTrackingMode" class="tw-flex tw-items-center tw-h-10 mt-1">
            <ui-radio-group
              :label="'Tracking Mode'"
              layout="horizontal"
              :values="networkTrackingModes"
              tip="_walletTrackingMode"
              v-model="defaultTrackMode"
            ></ui-radio-group>
          </div>
        </div>
      </v-layout>
    </v-flex>

    <v-flex xs12 sm12 md6>
      <v-btn @click="save" :disabled="!details.isValid || savingWallet" color="primary text-capitalize">{{
        $t('_save')
      }}</v-btn>
    </v-flex>
  </v-flex>
</template>

<script>
import gql from 'graphql-tag';

import UiRadioGroup from '@/components/ui/UiRadioGroup.vue';

export default {
  components: { UiRadioGroup },
  props: ['details', 'networkId', 'subsidiaryId', 'walletRoleId'],
  data() {
    return {
      name: '',
      description: '',
      address: '',
      savingWallet: false,
      rollup: false,
      rollupPeriod: '',
      rollupSelector: '',
      rollupSelectors: ['revenue', 'fees', 'custom'],
      rollupPeriods: ['hour', 'day'], // 1d
      immutableXConfig: 'normal',
      rollupRevenue: false,
      rollupTokenRevenue: false,
      rollupFee: false,
      rollupTokenRemittance: false,
      rollupRemittance: false,
      rollupAll: false,
      accountCheck: [],
      seperateByAccount: false,
      logSelector: [],
      seperateByLog: false,
      trackingModes: [
        { value: null, label: 'None', enabled: true },
        { value: 'transaction_balance', label: 'Transaction + Balance', enabled: false },
        { value: 'balance', label: 'Balance', enabled: false },
        { value: 'balance_delta', label: 'Balance Delta', enabled: false },
      ],
      trackMode: 'balance',
    };
  },
  watch: {
    name() {
      this.checkFormValid();
    },
    address() {
      this.checkFormValid();
    },
    description() {
      this.checkFormValid();
    },
  },
  computed: {
    canRollup() {
      const s = new Set([
        'eth',
        'immutable',
        'klaytn',
        'sol',
        'flow',
        'hbar',
        'apt',
        'xrp',
        'polygon',
        'bsc',
        'avaxc',
        'dot',
        'op',
        'gnosis',
        'ksm',
        'osmo',
        'cosmos',
        'arb',
        'base',
        'beacon',
        'celo',
        'aurora',
        'ftm',
        'xlm',
        'canton',
        'zeta',
      ]);
      return s.has(this.networkId); // && this.checkFeatureFlag('rollups', this.features);
    },
    customRollup() {
      return this.rollupSelector.valueOf() === 'custom';
    },
    isImmutableXConfig() {
      const s = new Set(['immutable']);
      return s.has(this.networkId) && this.checkFeatureFlag('immutable-config', this.features);
    },
    hasTrackingMode() {
      const walletTypeSet = new Set(['canton']);
      return walletTypeSet.has(this.networkId);
    },
    networkTrackingModes() {
      if (this.networkId === 'canton') {
        this.trackingModes.map((tm) => {
          if (tm.value === 'none') return tm;
          if (tm.value === 'balance_delta') tm.enabled = true;
          // else tm.enabled = false;
          return tm;
        });
      }
      return this.trackingModes.filter((tm) => tm.enabled);
    },
    defaultTrackMode() {
      if (this.networkId === 'canton') {
        return null;
      }
      return this.trackMode;
    },
  },
  methods: {
    checkFormValid() {
      // eslint-disable-next-line vue/no-mutating-props
      this.details.isValid =
        this.name.trim().length > 0 && this.address.length !== 0 && this.address.indexOf(' ') === -1 && this.networkId;
    },
    resetForm() {
      this.name = '';
      this.address = '';
      this.savingWallet = false;
    },

    async save() {
      this.savingWallet = true;

      const orgId = this.$store.state.currentOrg.id;
      const prems = [
        {
          userId: this.$store.state.user.id,
          role: 4,
        },
      ];
      let rollupConfig;
      if (this.rollup) {
        if (this.rollupSelector === '' || this.rollupPeriod === '') {
          this.showErrorSnackbar('If setting rollups, must select type and period');
          return;
        }

        rollupConfig = {
          rollupPeriod: this.rollupPeriod,
          rollupSelector: this.rollupSelector,
          rollupRevenue: this.rollupRevenue,
          rollupTokenRevenue: this.rollupTokenRevenue,
          rollupFee: this.rollupFee,
          rollupTokenRemittance: this.rollupTokenRemittance,
          rollupRemittance: this.rollupRemittance,
          rollupAll: this.rollupAll,
          accountCheck: this.accountCheck,
          seperateByAccount: this.seperateByAccount,
          logSelector: this.logSelector,
          seperateByLog: this.seperateByLog,
        };
      }

      let immutableXConfig;
      if (this.isImmutableXConfig) {
        immutableXConfig = this.immutableXConfig;
      }

      const accountBasedBlockchain = {
        address: this.address,
        networkId: this.networkId,
        rollupConfig,
        metadata: { immutableXConfig },
      };

      const wallet = {
        type: 'accountBasedBlockchain',
        name: this.name,
        subsidiaryId: this.subsidiaryId,
        walletRoleId: this.walletRoleId,
        accountBasedBlockchain,
      };

      if (this.hasTrackingMode) {
        wallet.flags = { ...wallet.flags, trackingMode: this.trackMode };
      }

      const variables = {
        orgId,
        wallet,
        prems,
      };
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($orgId: ID!, $wallet: WalletInput!, $prems: [WalletPermissionInput]!) {
            createWallet(orgId: $orgId, wallet: $wallet, prems: $prems) {
              id
            }
          }
        `,
        variables,
      });
      this.savingWallet = false;
      this.resetForm();
      this.$root.$emit('refresh-wallets');
      this.$emit('wallet-saved');
    },
    async addAccount() {
      this.accountCheck.push('<involved account address>');
    },
    async addLogSelector() {
      this.logSelector.push('log string like');
    },
  },
};
</script>
