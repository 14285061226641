











import * as _ from 'lodash';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import type { IsValidAddress } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';

import UiTextEdit from '../../components/ui/UiTextEdit.vue';
import { validWalletAddress } from '../../queries/walletQueries';

@Component({
  components: {
    UiTextEdit,
  },
})
export default class ManualOtherInput extends BaseVue {
  public description = '';
  public isValid = false;
  public debouncedValidate = _.debounce(this.validateDescription.bind(this), 100);

  public async validateDescription() {
    this.isValid = this.description.trim().length > 0;
  }

  @Watch('description')
  public onDescriptionChanged() {
    this.debouncedValidate();
    this.$emit('data', { description: this.description });
  }

  @Watch('isValid')
  public validChanged() {
    this.$emit('valid', this.isValid);
  }
}
