export const networks = [
  { text: 'Bitcoin', value: 'btc' },
  { text: 'BitcoinCash', value: 'bch' },
  { text: 'BitcoinGold', value: 'btg' },
  { text: 'Ethereum', value: 'eth' },
  { text: 'Dash', value: 'dash' },
  { text: 'EOS', value: 'eos' },
  { text: 'Arbitrum', value: 'arb' },
  { text: 'Litecoin', value: 'ltc' },
  { text: 'Stellar', value: 'xlm' },
  { text: 'Canton', value: 'canton' },
  { text: 'ZetaChain', value: 'zeta' },
  { text: 'Zcash', value: 'zec' },
  { text: 'Tron', value: 'trx' },
  { text: 'Harmony', value: 'one' },
  { text: 'Solana', value: 'sol' },
  { text: 'Polkadot', value: 'dot' },
  { text: 'Tezos', value: 'xtz' },
  { text: 'Secret', value: 'scrt' },
  { text: 'Avalanche', value: 'avax' },
  { text: 'AvalancheC', value: 'avaxc' },
  { text: 'Aion', value: 'aion' },
  { text: 'Icon', value: 'icx' },
  { text: 'Atom', value: 'atom' },
  { text: 'Band', value: 'band' },
  { text: 'Kava', value: 'kava' },
  { text: 'IRIS', value: 'iris' },
  { text: 'Filecoin', value: 'fil' },
  { text: 'Oasis', value: 'rose' },
  { text: 'Near', value: 'near' },
  { text: 'BSV', value: 'bsv' },
  { text: 'Verge', value: 'xvg' },
  { text: 'Polygon', value: 'polygon' },
  { text: 'Celo', value: 'celo' },
  { text: 'Etc', value: 'etc' },
  { text: 'Raven', value: 'rvn' },
  { text: 'Doge', value: 'doge' },
  { text: 'Cardano', value: 'ada' },
  { text: 'Mina', value: 'mina' },
  { text: 'Flow', value: 'flow' },
  { text: 'Kusama', value: 'ksm' },
  { text: 'Hadera', value: 'hbar' },
  { text: 'Ripple', value: 'xrp' },
  { text: 'Omni', value: 'omni' },
  { text: 'Bsc', value: 'bsc' },
  { text: 'HecoChain', value: 'heco' },
  { text: 'Terra', value: 'terra' },
  { text: 'Klayton', value: 'klay' },
  { text: 'Algo', value: 'algo' },
  { text: 'Loopring', value: 'lrc' },
  { text: 'Casper', value: 'casper' },
  { text: 'Stacks', value: 'stx' },
  { text: 'Bnb', value: 'bnb' },
  { text: 'Cosmos', value: 'cosmos' },
  { text: 'Fantom', value: 'ftm' },
  { text: 'Gnosis', value: 'gnosis' },
  { text: 'Optimism', value: 'op' },
  { text: 'Moonbeam', value: 'moon' },
  { text: 'Icp', value: 'icp' },
  { text: 'Iost', value: 'iost' },
  { text: 'Iota', value: 'iota' },
  { text: 'Immutable', value: 'imx' },
  { text: 'EthPOW', value: 'ethw' },
  { text: 'Ronin', value: 'ronin' },
  { text: 'Shimmer', value: 'shimmer' },
  { text: 'Neo', value: 'neo' },
  { text: 'Qtum', value: 'qtum' },
  { text: 'Songbird', value: 'sgb' },
  { text: 'Vchain', value: 'vet' },
  { text: 'Aurora', value: 'aurora' },
  { text: 'Aptos', value: 'apt' },
  { text: 'Ontology', value: 'ont' },
  { text: 'Sia', value: 'sc' },
  { text: 'Nem', value: 'xem' },
  { text: 'Monero', value: 'xmr' },
];
