<template>
  <v-layout row wrap>
    <v-snackbar :color="snackbarColor" v-model="snackbar" :timeout="4000" :top="true">
      {{ snackbarText }}
    </v-snackbar>
    <v-flex offset-xs1 xs10>
      <v-text-field :label="$t('_name')" v-model="details.name"></v-text-field>
    </v-flex>
    <v-flex offset-xs1 xs10>
      <v-select :items="coins" :label="$tc('_coin')" v-model="newCoin"></v-select>
    </v-flex>
    <v-flex offset-xs1 xs10>
      <v-select
        :items="multisigModes"
        :label="$t('_multisigMode')"
        v-model="newMultisigMode"
        :disabled="!newCoin"
        item-text="title"
        item-value="id"
      ></v-select>
    </v-flex>
    <v-flex offset-xs1 xs10>
      <v-text-field
        :label="$t('_minCosigners')"
        v-model="minCosigners"
        :rules="[rules.required, rules.number]"
        :disabled="!newCoin"
      ></v-text-field>
    </v-flex>
    <v-flex offset-xs1 xs10 v-if="newMultisigMode === 'custom'">
      <v-layout row wrap>
        <v-flex xs4>
          <v-checkbox v-model="customUseChange" label="Use Change" />
        </v-flex>
        <v-flex xs4>
          <v-checkbox v-model="customSortPublicKeys" label="Sort Public Keys" />
        </v-flex>
        <v-flex xs12>
          <v-select
            v-model="customDerivationMode"
            label="Derivation Mode"
            :items="derivationModes"
            item-text="name"
            item-value="id"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field v-model="customPrependPath" label="Prepend Path (EG /0/1)" />
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex offset-xs1 xs10 v-for="(hd, index) in hds" v-bind:key="`hd-${index}`">
      <v-layout row wrap>
        <v-flex xs10>
          <v-text-field
            :label="$tc('_extendedPublicKey', 1)"
            v-model="hd.extendedPublicKey"
            :rules="[rules.required]"
            :disabled="!newCoin"
          ></v-text-field>
        </v-flex>
        <v-flex xs2>
          <a @click="deleteHD(hd)"><v-icon>delete</v-icon></a>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex offset-xs5 xs2 class="text-xs-right">
      <a @click="addHD">Add Key Derivation +</a>
    </v-flex>
    <v-flex xs12 sm12 md6>
      <v-btn @click="save" color="primary text-capitalize" :disabled="!this.isValid">{{ $t('_save') }}</v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import 'firebase/firestore';

import gql from 'graphql-tag';

export default {
  props: ['details', 'walletRoleId'],
  data() {
    return {
      // addressTypes: ["Simple", "XPUB"],
      coins: ['BTC'],
      minCosigners: 1,
      newMultisigMode: '',
      newCoin: '',
      hds: [{ extendedPublicKey: '' }],
      rules: {
        number: (value) => !isNaN(Number(value)) || 'Invalid Number',
        required: (value) => !!value || 'Required.',
      },
      customUseChange: true,
      customSortPublicKeys: true,
      customDerivationMode: '',
      customPrependPath: '',
      derivationModes: [
        {
          name: 'Legacy',
          id: 'legacy',
        },
        {
          name: 'P2SH Segwit',
          id: 'p2shSegwit',
        },
        {
          name: 'Native Segwit',
          id: 'nativeSegwit',
        },
      ],
      snackbarText: '',
      snackbarColor: '',
      snackbar: false,
    };
  },
  computed: {
    isValid() {
      // HDs must all be defined
      // HDs.legnth > 0
      // minCosigners <= hds.length
      return this.validationErrors.length === 0;
    },
    validationErrors() {
      const errors = [];
      const mcs = Number(this.minCosigners);

      if (mcs > this.hds.length) {
        errors.push('Minimum Cosigners must be fewer than the number of extended keys');
      }
      for (const hd of this.hds) {
        if (hd.extendedPublicKey.trim() === '') {
          errors.push('Missing Extended Key');
        }
      }

      return errors;
    },
    multisigModes() {
      if (this.newCoin === 'BTC') {
        return [
          {
            title: 'Electrum Legacy (xpub)',
            id: 'ElectrumLegacyBTC',
          },
          {
            title: 'Electrum P2SH (Ypub)',
            id: 'ElectrumP2SHSegwitBTC',
          },
          {
            title: 'Electrum Segwit (Zpub)',
            id: 'ElectrumSegwitBTC',
          },
          {
            title: 'Bitcoin.com',
            id: 'Bitcoincom',
          },
          {
            title: 'Custom',
            id: 'custom',
          },
        ];
      } else return [];
    },
  },
  methods: {
    addHD() {
      this.hds.push({ extendedPublicKey: '' });
    },
    deleteHD(item) {
      const newHDs = this.hds.filter((m) => m !== item);
      this.hds = newHDs;
    },
    save() {
      const orgId = this.$store.state.currentOrg.id;
      const name = this.details.name;

      const hdsArray = this.hds;
      for (const hd of this.hds) {
        if (hdsArray.filter((x) => x.extendedPublicKey.trim() === hd.extendedPublicKey.trim()).length > 1) {
          this.snackbar = true;
          this.snackbarText = 'Error creating wallet: Extended Public Key cannot be repeated';
          this.snackbarColor = 'error';
          return;
        }
      }

      // TODO extract magic string / numbers below
      const prems = [
        {
          userId: this.$store.state.user.id,
          role: 4,
        },
      ];

      /*
          input MultisigWallet {
            mode: MultisigWalletMode!
            minCosigners: Int!
            hds: [String]!
          }
       */

      const mcs = Number(this.minCosigners);

      const wallet = {
        name: name,
        type: 'multisig',
        multisig: {
          mode: this.newMultisigMode,
          minCosigners: mcs,
          hds: this.hds.map((m) => m.extendedPublicKey.trim()),
        },
        walletRoleId: this.walletRoleId,
      };

      if (this.newMultisigMode === 'custom') {
        wallet.multisig.customUseChange = this.customUseChange;
        wallet.multisig.customSortPublicKeys = this.customSortPublicKeys;
        wallet.multisig.customDerivationMode = this.customDerivationMode;
        if (this.customPrependPath !== '') {
          wallet.multisig.customPrependPath = this.customPrependPath;
        }
      }

      const vars = {
        wallet,
        orgId,
        prems,
      };

      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($orgId: ID!, $wallet: WalletInput!, $prems: [WalletPermissionInput]!) {
              createWallet(orgId: $orgId, wallet: $wallet, prems: $prems) {
                id
              }
            }
          `,
          // Parameters
          variables: vars,
        })
        .then(() => {
          console.log('added wallet');
          this.resetWallet();
          this.$root.$emit('refresh-wallets');
          this.$emit('wallet-saved');
        });
    },
    resetWallet() {
      this.details.name = '';
      this.minCosigners = 1;
      this.newMultisigMode = '';
      this.newCoin = '';
      this.hds = [{ extendedPublicKey: '' }];
    },
    addAddress() {
      let c = -1;
      if (this.newCoin === 'BTC') {
        c = 1;
      } else if (this.newCoin === 'ETH') {
        c = 10;
      } else if (this.newCoin === 'EOS') {
        c = 20;
      }

      const na = {
        coin: c,
      };

      if (this.newType === 'Simple') {
        na.type = 1;
        na.address = this.newAddress;
      } else if (this.newType === 'XPUB') {
        na.type = 2;
        na.derivationKey = this.newAddress;
      }

      // const na = {
      //   address: this.newAddress,
      //   type: this.newType
      // };
      (this.details.addresses = this.details.addresses || []).push(na);

      this.newAddress = '';
      this.newType = '';
      this.newCoin = '';

      this.details.isValid = true;
    },
  },
};
</script>
