<template>
  <div>
    <div class="tw-border-b tw-border-gray-300">
      <div class="tw-flex tw-items-center tw-justify-between tw-px-6 tw-py-5">
        <div style="display: flex; align-items: center; gap: 5px">
          <div class="tw-flex tw-gap-2">
            <h3 class="tw-text-2xl tw-capitalize tw-font-medium">
              {{ $tc('_wallet', 2) }}
            </h3>
            <div class="tw-flex tw-items-center tw-gap-2 tw-text-gray-700 tw-font-medium tw-pl-2">
              <span :class="{ 'tw-text-netural-500': !isNew, 'tw-text-gray-400': isNew }">Legacy UI</span>
              <ui-toggle v-model="isNew" @input="toggleUi"></ui-toggle>
              <span :class="{ 'tw-text-neutral-500': isNew, 'tw-text-gray-400': !isNew }">New UI</span>
            </div>
          </div>
          <tooltip-select tip="_wallets"></tooltip-select>
        </div>
        <tooltip-select tip="_walletAdds" class="tw-absolute tw-right-2 tw-top-21 tw-z-10"></tooltip-select>
        <create-wallet v-if="!isReadOnly && !checkFeatureFlag('disable-create-wallet', features)" />
      </div>
    </div>
    <div class="tw-py-4 tw-px-3">
      <wallet-list :short="false" minHeight="300" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { ScopeLiterals } from '@/api-svc-types';
import CreateWallet from '@/components/CreateWallet';
import TooltipSelect from '@/components/tooltip/TooltipSelect.vue';
import UiToggle from '@/components/ui/UiToggle';
import WalletList from '@/components/WalletList';

import { checkScope } from '../../utils/security';

export default {
  props: [],
  data: () => ({
    pagination: {
      sortBy: 'name',
    },
    selected: [],
    headers: [
      {
        text: 'Wallet',
        align: 'left',
        value: 'name',
      },
      { text: 'Balance', value: 'balance' },
    ],
    isNew: false,
  }),

  computed: {
    ...mapGetters({
      features: 'features',
    }),
    isReadOnly() {
      return !checkScope(ScopeLiterals.WalletsCreate);
    },
  },

  methods: {
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.desserts.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    toggleUi(val) {
      this.$emit('toggleUi', val);
      localStorage.setItem('walletsUi', val ? 'new' : 'old');
    },
  },

  components: {
    CreateWallet,
    walletList: WalletList,
    TooltipSelect,
    UiToggle,
  },
};
</script>
