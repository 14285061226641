


















import { debounce } from 'lodash';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';

import UiSelect from '../ui/UiSelect.vue';
import UiTextEdit from '../ui/UiTextEdit.vue';

@Component({
  components: {
    UiTextEdit,
    UiSelect,
  },
})
export default class BlockchainAddressInput extends BaseVue {
  public apiKey = '';
  public apiSecret = '';
  public exchangeContactId = '';

  public isValid = false;
  public debouncedValidate = debounce(this.validateForm.bind(this), 1000);

  public async validateForm() {
    this.isValid = !!this.apiKey && !!this.apiSecret && !!this.exchangeContactId;
  }

  public get contacts() {
    const contacts = this.$store.getters['contacts/ENABLED_CONTACTS'];
    const res: any = {};
    contacts.forEach((contact: any) => (res[contact.name] = contact.id));
    return res;
  }

  @Watch('apiKey')
  @Watch('apiSecret')
  @Watch('exchangeContactId')
  public onWalletTypeChanged() {
    this.debouncedValidate();
    this.$emit('data', {
      createCoinbaseCustody: {
        apiKey: this.apiKey,
        apiSecret: this.apiSecret,
        exchangeContactId: this.exchangeContactId,
      },
    });
  }

  @Watch('isValid')
  public validChanged() {
    this.$emit('valid', this.isValid);
  }
}
