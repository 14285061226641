<template>
  <v-layout row wrap>
    <v-flex xs3 class="tw-mb-4">
      <ui-text-edit class="tw-text-normal" v-model="searchWallet" :label="'Search by wallet name, id or address:'">
      </ui-text-edit>
    </v-flex>
    <v-flex xs12 class="elevation-1">
      <v-data-table
        :headers="headers"
        :items="filteredWallets"
        :pagination.sync="pagination"
        item-key="id"
        :hide-actions="!wallets || wallets.length <= 5"
        :loading="isLoading ? 'accent' : false"
        no-data-text=""
        style="background-color: white"
        class="pa-2"
        :rows-per-page-items="pageRows"
      >
        <template slot="headers" slot-scope="props">
          <tr>
            <th
              v-for="header in props.headers.filter(
                (x) => x.featureFlag === undefined || checkFeatureFlag(x.featureFlag, features)
              )"
              :key="header.text"
              :class="[
                'column text-capitalize sortable text-xs-' + header.align,
                pagination.descending ? 'desc' : 'asc',
                header.value === pagination.sortBy ? 'active' : '',
              ]"
              @click="changeSort(header.value)"
              :width="header.width"
            >
              {{ header.text }}
              <v-icon small>arrow_upward</v-icon>
            </th>
          </tr>
        </template>
        <template slot="items" slot-scope="props">
          <tr style="height: 60px">
            <td class="text-xs-left pl-2 pt-2 ma-1" style="vertical-align: text-top; min-width: 350px">
              <b v-if="!short">{{ props.item.name }}</b>
              <span v-else>{{ props.item.name }}</span>

              <p class="small word-break" v-if="!short">
                {{ props.item.description }}
              </p>
            </td>
            <td class="short-name" style="vertical-align: center; padding: 0 10px" v-if="!short">
              <div>
                <table style="width: 100%">
                  <tr v-for="item in props.item.addresses" v-bind:key="item">
                    <td
                      style="
                        padding: 0 5px 0 14px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 100%;
                        max-width: 90px;
                      "
                    >
                      <span style="width: auto">{{ item }} </span>
                    </td>
                    <td style="padding: 0 10px 0 5px">
                      <v-icon size="10" style="cursor: pointer" v-clipboard:copy="item"> content_copy </v-icon>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
            <td class="pa-0 ma-0 align-right" style="min-width: 250px">
              <div>
                <div v-if="isLoadingBalance">
                  <div class="tw-animate-pulse tw-w-3/4 tw-h-5 tw-bg-gray-300"></div>
                </div>
                <table style="width: 100%" class="align-right" v-else-if="getWalletBalance(props.item.id)">
                  <!--<tr>-->
                  <!--<th width="20%"></th>-->
                  <!--<th width="40%"></th>-->
                  <!--<th width="40%"></th>-->
                  <!--</tr>-->
                  <tr v-for="item in getWalletBalance(props.item.id)" v-bind:key="item.coin" class="currency-table">
                    <td class="number-cell pa-0 text-xs-right balance-td" style="width: 100px; overflow-x: hidden">
                      <div style="float: left">
                        <b>{{ getSymbolForCoin(item.coin) }}</b>
                      </div>
                      {{ item.displayValue }}
                    </td>
                    <td class="balance-td" style="width: 10px"></td>
                    <td class="number-cell pa-0 pl-1 pr-1 text-xs-right balance-td" style="width: 80px">
                      <div style="float: left">
                        <b>{{ getSymbolForCurrency(item.fiatValue ? item.fiatValue.currency : '??') }}</b>
                      </div>
                      {{ item.fiatValue ? item.fiatValue.displayValue : '??' }}
                    </td>
                  </tr>
                  <tr style="height: 12px">
                    <td class="pa-0 balance-td" style="width: 100px"></td>
                    <td class="balance-td" style="width: 10px"></td>
                    <td
                      class="number-cell pa-0 pr-1 pl-1 text-xs-right text-no-wrap balance-td"
                      style="border-top: 1px solid gray; width: 60px"
                    >
                      <div style="float: left">
                        <b>{{ getWalletTotalFiatCurrency(props.item.id) }}</b>
                      </div>
                      <b>{{ getWalletTotalFiatValue(props.item.id) }}</b>
                    </td>
                  </tr>
                </table>
                <div class="text-xs-center" v-else>
                  <i>Balance Unavailable</i>
                </div>
              </div>
            </td>
            <td class="pl-4 ma-0 align-right" style="min-width: 250px">
              {{ props.item.walletRole }}
            </td>
            <td v-if="!short" class="text-xs-center">
              <div v-if="props.item.bulkSend && props.item.bulkSend.enabled" color="success">
                <v-icon color="success">checkmark</v-icon>
              </div>
              <div v-else-if="props.item.bulkSend && !props.item.bulkSend.enabled && props.item.bulkSend.canEnable">
                <enable-wallet-for-bulk :wallet="props.item" @refresh="refresh" />
              </div>
              <div v-else>N/A</div>
            </td>
            <td class="text-xs-right text-no-wrap pa-3 ma-0" style="width: 20%" v-if="showActions">
              <div v-if="props.item.type === 2 || props.item.type === 5">
                <receive :wallet-id="props.item.id" :wallet="props.item" :enabled-coins="props.item.enabledCoins" />
                <send :wallet="props.item" :wallet-id="props.item.id" :enabled-coins="props.item.enabledCoins" />
              </div>
            </td>
            <td class="text-xs-center" v-if="!short">
              <v-btn @click="showWalletDetails(props.item)">Details</v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
    <v-dialog v-model="viewDialog" width="500" persistent>
      <v-card>
        <v-card-title>
          <v-layout flex row>
            <v-flex xs8 class="tw-text-base"> Wallet Details </v-flex>
            <v-flex class="text-xs-right" xs4 offset-xs8>
              <v-icon @click="closeDetails" class="text-xs-right">close</v-icon>
            </v-flex>
          </v-layout>
        </v-card-title>
        <view-wallet :wallet="viewWallet" :close="closeDetails" />
      </v-card>
    </v-dialog>
  </v-layout>

  <!--<v-card-text class="pa-0" >-->
  <!---->
  <!--</v-card-text>-->
  <!---->
</template>

<script>
import gql from 'graphql-tag';
import { find as lodashFind } from 'lodash';

import { WalletService } from '@/services/walletService';

import { baConfig } from '../../config';
import { ApiSvcWalletResponseDTO, WalletsApi } from '../../generated/api-svc';
import { getSymbolForCoin, getSymbolForCurrency } from '../utils/coinUtils';
import Receive from './Receive.vue';
import Send from './Send.vue';
import UiTextEdit from './ui/UiTextEdit.vue';
import EnableWalletForBulk from './wallets/EnableWalletForBulk';
import ViewWallet from './wallets/ViewWallet';

export default {
  props: ['cardStyle', 'short'],
  data() {
    return {
      pagination: {
        sortBy: 'name',
      },
      btc_rate: 0,
      wallets: [],
      walletRoles: [],
      walletBalances: [],
      orgs: [],
      isLoading: 0,
      isLoadingBalance: 0,
      viewWallet: null,
      viewDialog: false,
      filteredWallets: [],
      searchWallet: '',
    };
  },
  async mounted() {
    await this.getWalletRoles();
    await this.loadWallets();

    this.refresh();
    this.$root.$on('refresh-wallets', () => {
      this.refresh();
    });
  },
  computed: {
    features() {
      return this.$store.getters.features;
    },
    showBalance() {
      // return this.checkFeatureFlag('wallets.balance', this.features);
      return true;
    },
    pageRows() {
      if (this.short === 'false') {
        return [10, 50, 100, { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }];
      }

      return undefined;
    },
    showActions() {
      const actionWallet = lodashFind(this.wallets, (m) => m.type !== 1);
      if (actionWallet) {
        return true;
      } else {
        return false;
      }
    },
    headers() {
      const headers = [
        {
          text: this.$tc('_wallet', 2),
          align: 'left',
          value: 'name',
          width: '280px',
        },
        {
          text: this.$t('_balance'),
          align: 'center',
          value: 'balance',
          width: '40%',
        },
      ];

      if (this.checkFeatureFlag('soda-report')) {
        headers.push({
          text: this.$tc('_role'),
          align: 'center',
          value: 'walletRoleId',
          width: '150px',
        });
      }

      if (!this.short) {
        headers.splice(1, 0, {
          text: this.$tc('_address', 2),
          align: 'left',
          value: 'address',
          width: '150px',
        });
      }

      if (!this.short) {
        headers.push({
          text: this.$t('_bulkSend'),
          align: 'center',
          value: 'bulkSend',
        });
      }

      if (this.showActions) {
        headers.push({
          text: this.$tc('_action', 2),
          align: 'right',
          value: 'actions',
          width: '20%',
          show: this.showActions,
        });
      }

      if (!this.short) {
        // headers.push({
        //   text: this.$t("_id"),
        //   align: "center",
        //   value: "id"
        // });

        headers.push({
          text: '',
          align: 'center',
          value: 'id',
        });
      }

      return headers;
    },
    walletItems() {
      return this.wallets.map((item) => {
        const walletRoleId = item.walletRoleId;
        if (this.walletRoles.some((wr) => wr.id === walletRoleId)) {
          item.walletRole = this.walletRoles.find((wr) => wr.id === walletRoleId).name;
        } else {
          item.walletRole = 'Default';
        }

        return item;
      });
    },
  },
  watch: {
    '$store.state.isUpdateWallet': function () {
      this.refresh();
    },
    searchWallet: function () {
      if (this.searchWallet.length) {
        this.filteredWallets = this.walletItems.filter((wallet) => {
          const searchValue = this.searchWallet.toLowerCase();
          return (
            wallet.name.toLowerCase().includes(searchValue) ||
            wallet.id.toLowerCase().includes(searchValue) ||
            wallet.addresses.includes(this.searchWallet)
          );
        });
      } else {
        this.filteredWallets = this.walletItems;
      }
      console.log(this.searchWallet, '>> item search <<');
    },
  },
  methods: {
    async loadWallets() {
      this.isLoading++;
      this.isLoadingBalance++;
      try {
        const apiSvc = new WalletsApi(undefined, baConfig.getFriendlyApiUrl());
        const options = { withCredentials: true };
        const resp = await apiSvc.getWallets(
          this.$store.state.currentOrg.id,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true,
          true,
          undefined,
          undefined,
          false, // excludeDisabled: bool - set false for parity with graphql call
          options
        );
        const wallets = resp.data.items.map((m) => this.fromWalletDto(m));
        this.wallets = wallets;
      } catch (error) {
        console.error('Error loading wallets:', error);
        this.showErrorSnackbar('Failed to load wallets. Error: ' + error);
      } finally {
        this.isLoadingBalance--;
        this.isLoading--;

        // load wallets
        this.filteredWallets = this.walletItems;
      }
    },
    fromWalletDto(wallet) {
      const value = {
        ...wallet,
        flags: wallet.flags,
        root: false,
        leaf: true,
        expanded: false,
        children: [],
      };
      return value;
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    getSymbolForCoin(coin) {
      return getSymbolForCoin(coin);
    },
    getSymbolForCurrency(coin) {
      return getSymbolForCurrency(coin);
    },
    getWalletBalance(id) {
      const wallet = this.wallets.find((wallet) => wallet.id === id);
      if (!wallet) {
        return;
      }
      return wallet.balance?.balances;
    },
    getWalletTotalFiat(id) {
      const wallet = this.wallets.find((wallet) => wallet.id === id);
      if (!wallet) {
        return;
      }
      return wallet.balance?.totalFiatValue;
    },
    getWalletTotalFiatCurrency(id) {
      return this.getWalletTotalFiat(id)?.currency;
    },
    getWalletTotalFiatValue(id) {
      return this.getWalletTotalFiat(id)?.displayValue;
    },
    refresh() {
      // this.$apollo.queries.wallets.refetch();
      // this.$apollo.queries.walletBalances.refetch();
    },
    showWalletDetails(wallet) {
      this.viewDialog = true;
      this.viewWallet = wallet;
    },
    closeDetails() {
      this.viewDialog = false;
      this.viewWallet = null;
    },
    async getWalletRoles() {
      const walletSvc = new WalletService(this.checkFeatureFlag('soda-report'));
      this.walletRoles = await walletSvc.getWalletRoles(this.$store.state.currentOrg.id);
    },
  },
  components: {
    ViewWallet,
    Send,
    Receive,
    EnableWalletForBulk,
    UiTextEdit,
  },
};
</script>
<style scoped>
.short-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.short-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.number-cell:hover {
  background-color: #f9f9f9;
}

.balance-td {
  height: 25px;
}
.word-break {
  word-break: break-all;
}
</style>
