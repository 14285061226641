<template>
  <v-flex xs12>
    <v-flex xs12>
      <v-text-field
        label="Name"
        v-model="details.name"
        :disabled="submittingWallet"
        :loading="submittingWallet ? 'accent' : false"
      ></v-text-field>
    </v-flex>
    <v-flex xs12>
      <v-select
        :items="users"
        item-value="id"
        label="Signatories"
        v-model="signers"
        multiple
        :disabled="submittingWallet"
        :loading="submittingWallet ? 'accent' : false"
        chips
      >
        <template slot="selection" slot-scope="data">
          <v-chip
            close
            @input="data.parent.selectItem(data.item)"
            :selected="data.selected"
            class="chip--select-multi"
            :key="JSON.stringify(data.item)"
          >
            <v-avatar v-if="data.item.photoUrl">
              <img :src="data.item.photoUrl" />
            </v-avatar>
            {{ data.item.name }}
          </v-chip>
        </template>
        <template slot="item" slot-scope="data">
          <template>
            <v-list-tile-action>
              <v-checkbox :input-value="signers.indexOf(data.item.id) > -1"></v-checkbox>
            </v-list-tile-action>
            <v-list-tile-avatar v-if="data.item.photUrl">
              <img :src="data.item.photoUrl" />
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ data.item.name }}</v-list-tile-title>
              <v-list-tile-sub-title>{{ data.item.email }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </template>
        </template>
      </v-select>
    </v-flex>
    <v-flex xs12>
      <v-text-field
        label="Minimum Number of Signers"
        v-model.number="minSigs"
        type="number"
        mask="#"
        :disabled="submittingWallet"
        :loading="submittingWallet ? 'accent' : false"
      ></v-text-field>
    </v-flex>
    <v-flex xs12>
      <v-btn @click="save" :disabled="!details.isValid || submittingWallet" color="primary">Save</v-btn>
    </v-flex>
  </v-flex>
</template>

<script>
import gql from 'graphql-tag';

import { createWalletMutation } from '../../mutations/walletMutations';
export default {
  props: ['details', 'walletRoleId'],
  data() {
    return {
      users: [],
      // isValid: false,
      newRole: '',
      newUsers: [],
      signers: [this.$store.state.user.id],
      minSigs: 1,
      roles: [
        {
          name: 'Full Control',
          id: 'fc',
        },
        {
          name: 'Request',
          id: 'request',
        },
        {
          name: 'Read Only',
          id: 'ro',
        },
      ],
      submittingWallet: false,
    };
  },
  watch: {
    signers() {
      var containsCurrentUser = false;
      console.log(this.signers);
      for (var i in this.signers) {
        console.log('checking signers id', this.signers[i].id, "against the user's id", this.$store.state.user.id);
        if (this.signers[i] === this.$store.state.user.id) {
          console.log('found current user');
          containsCurrentUser = true;
        }
      }
      if (!containsCurrentUser) {
        console.log('adding current user because did not find it');
        this.addCurrentUserToSigners();
      }
      this.checkValid();
    },
    minSigs() {
      this.checkValid();
    },
  },
  methods: {
    addCurrentUserToSigners() {
      this.signers.push(this.$store.state.user.id);
    },
    resetWallet() {
      this.minSigs = 1;
      this.details.name = '';
      this.signers = [];
      this.submittingWallet = false;
    },
    save() {
      const minCosigners = this.minSigs;
      const orgId = this.$store.state.currentOrg.id;
      const name = this.details.name;
      const cosigners = this.signers.filter((e) => e !== undefined);
      // TODO extract magic string / numbers below
      const prems = cosigners.map((m) => {
        return {
          userId: m,
          role: 4,
        };
      });
      const wallet = {
        name: name,
        type: 'eh',
        eh: {
          cosigners,
          minCosigners,
        },
        walletRoleId: this.walletRoleId,
      };
      const variables = {
        wallet,
        orgId,
        prems,
      };
      this.$apollo
        .mutate({
          mutation: createWalletMutation,
          variables,
        })
        .then(() => {
          console.log('added wallet');
          this.$emit('wallet-saved');
          this.resetWallet();
        });
    },
    checkValid() {
      if (this.signers.length >= 1 && this.minSigs > 0 && this.minSigs <= this.signers.length) {
        console.log('aca');
        this.details.isValid = true;
      } else {
        this.details.isValid = false;
      }
    },
    addUsers() {
      this.newUsers.forEach((u) => {
        var userRoles = this.details.usersAndRoles[u] || [];
        userRoles.push(this.newRole);
        this.details.usersAndRoles[u] = userRoles;
      });

      this.$forceUpdate();
      this.details.isValid = true;
    },
  },
  apollo: {
    users() {
      return {
        query: gql`
          query GetUsers($orgId: ID!) {
            org(id: $orgId) {
              users {
                id
                name
                email
              }
            }
          }
        `,
        update(data) {
          console.log(data);
          return data.org.users;
        },
        variables() {
          // Use vue reactive properties here
          return {
            orgId: this.$store.state.currentOrg.id,
          };
        },
        loadingKey: 'isLoading',
      };
    },
  },
};
</script>
