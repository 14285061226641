<template>
  <v-flex xs12 :key="this.bumpKey">
    <v-flex xs12>
      <v-text-field :label="$t('_name') + ' *'" v-model="name"></v-text-field>
    </v-flex>
    <v-flex xs12>
      <v-checkbox v-model="isSyncEnabled" :label="$t('_syncEnabled')" class="tw-mt-0" />
    </v-flex>
    <v-flex xs12>
      <v-autocomplete
        v-model="networkId"
        :items="networks"
        :label="$t('_networkId') + ' *'"
        item-text="text"
        item-value="value"
        persistent-hint
      ></v-autocomplete>
    </v-flex>
    <v-flex xs12>
      <v-text-field
        :label="$t('_walletAddress') + ' *'"
        placeholder="Enter the Wallet Address used to interact with the DeFi protocol"
        v-model="walletAddress"
      ></v-text-field>
    </v-flex>
    <v-flex xs12>
      <v-text-field
        :label="$t('_vaultAddress') + ' *'"
        placeholder="Enter DeFi contract address"
        v-model="vaultAddress"
      ></v-text-field>
    </v-flex>
    <!--
    <v-flex xs12>
      <v-select
        :items="activeConnections"
        v-model="connectionId"
        :label="$tc('_connectionId')"
        item-text="provider"
        item-value="id"
      ></v-select>
    </v-flex>
    -->
    <v-flex xs12>
      <v-select
        :items="groupWallets"
        v-model="groupId"
        :label="$tc('_groupId') + ' (optional)'"
        item-text="name"
        item-value="id"
      ></v-select>
    </v-flex>
    <v-flex xs12>
      <v-text-field :label="$t('_tokenId') + ' (optional)'" v-model="tokenId"></v-text-field>
    </v-flex>
    <v-flex xs12 sm12 md6>
      <v-btn @click="save" :disabled="!details.isValid || savingWallet" color="primary text-capitalize">{{
        $t('_save')
      }}</v-btn>
    </v-flex>
  </v-flex>
</template>

<script>
import gql from 'graphql-tag';

import { networks } from './constants';

export default {
  props: ['details', 'walletType', 'walletRoleId'],
  data() {
    return {
      name: '',
      isSyncEnabled: false,
      connectionId: '',
      groupId: '',
      networkId: '',
      walletAddress: '',
      vaultAddress: '',
      tokenId: '',
      savingWallet: false,
      connections: [],
      wallets: [],
      networks: networks,
      bumpKey: 0,
    };
  },
  mounted() {
    this.resetForm();
    if (this.details.name) {
      this.name = this.details.name;
    }
    if (this.details.networkId) {
      this.networkId = this.details.networkId;
    }
    if (this.details.vaultAddress) {
      this.vaultAddress = this.details.vaultAddress;
    }
    if (this.details.walletAddress) {
      this.walletAddress = this.details.walletAddress;
    }
    if (this.details.tokenId) {
      this.tokenId = this.details.tokenId;
    }
    this.bumpKey++;
  },
  apollo: {
    connections: {
      query: gql`
        query GetConnections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            provider
            lastSyncSEC
            isSetupComplete
            isDisabled
            isDeleted
            syncStatus {
              status
              lastSyncCompletedSEC
              errors
              warnings
              isRunning
            }
            name
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
    wallets: {
      query: gql`
        query GetWallets($orgId: ID!) {
          wallets(orgId: $orgId) {
            id
            name
            type
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
  },
  computed: {
    activeConnections() {
      const accoutingProviders = ['Xero', 'QuickBooks', 'NetSuite', 'SageIntacct'];
      return this.connections.filter((m) => !m.isDeleted && !accoutingProviders.includes(m.provider));
    },
    groupWallets() {
      return this.wallets.filter((w) => w.type === 999);
    },
  },
  watch: {
    name() {
      this.checkFormValid();
    },
    networkId() {
      this.checkFormValid();
    },
    isSyncEnabled() {
      this.checkFormValid();
    },
    walletAddress() {
      this.checkFormValid();
    },
    vaultAddress() {
      this.checkFormValid();
    },
  },
  methods: {
    checkFormValid() {
      this.details.isValid =
        this.name.trim().length > 0 &&
        this.networkId.trim().length > 0 &&
        this.walletAddress.trim().length > 0 &&
        this.walletAddress.idexOf(' ') === -1 &&
        this.vaultAddress.trim().length > 0 &&
        this.vaultAddress.idexOf(' ') === -1;
    },
    resetForm() {
      this.name = '';
      this.savingWallet = false;
    },
    async save() {
      this.savingWallet = true;
      const orgId = this.$store.state.currentOrg.id;
      const prems = [
        {
          userId: this.$store.state.user.id,
          role: 4,
        },
      ];
      const defi = {
        connectionId: this.connectionId ? this.connectionId : undefined,
        groupId: this.groupId ? this.groupId : undefined,
        networkId: this.networkId,
        isSyncEnabled: this.isSyncEnabled,
        walletAddress: this.walletAddress,
        vaultAddress: this.vaultAddress,
        tokenId: this.tokenId,
      };
      const wallet = {
        name: this.name,
        type: this.walletType,
        defi,
        walletRoleId: this.walletRoleId,
      };

      const variables = {
        orgId,
        wallet,
        prems,
      };

      await this.$apollo.mutate({
        mutation: gql`
          mutation ($orgId: ID!, $wallet: WalletInput!, $prems: [WalletPermissionInput]!) {
            createWallet(orgId: $orgId, wallet: $wallet, prems: $prems) {
              id
            }
          }
        `,
        variables,
      });
      this.savingWallet = false;
      this.resetForm();
      this.$root.$emit('refresh-wallets');
      this.$emit('wallet-saved');
    },
  },
};
</script>
