


















































import gql from 'graphql-tag';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import type { Wallet } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';

import { createWalletMutation } from '../../mutations/walletMutations';

@Component({
  apollo: {
    wallets: {
      query: gql`
        query GetWallets($orgId: ID!) {
          wallets(orgId: $orgId) {
            id
            name
            description
            type
            deviceType
            address
            addresses
            path
            enabledCoins
            bulkSend {
              enabled
              canEnable
            }
            groupId
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
      errorPolicy: 'ignore',
    },
  },
})
export default class GroupWallet extends BaseVue {
  wallets: Wallet[] = [];
  childWallets: string[] = [];
  submittingWallet = false;

  @Prop({ required: true })
  public readonly details: any;

  @Prop()
  public subsidiaryId?: string;

  resetWallet() {
    this.details.name = '';
    this.childWallets = [];
    this.submittingWallet = false;
  }

  save() {
    const orgId = this.$store.state.currentOrg.id;
    const name = this.details.name;
    const prems = [
      {
        userId: this.$store.state.user?.id,
        role: 4,
      },
    ];
    const wallet = {
      name: name,
      type: 'group',
      subsidiaryId: this.subsidiaryId,
      group: {
        children: this.childWallets,
      },
    };
    const variables = {
      wallet,
      orgId,
      prems,
    };
    this.$apollo
      .mutate({
        mutation: createWalletMutation,
        variables,
      })
      .then(() => {
        this.$emit('wallet-saved');
        this.resetWallet();
      });
  }

  checkValid() {
    if (this.childWallets.length >= 1) {
      this.details.isValid = true;
    } else {
      this.details.isValid = false;
    }
  }

  get nonGroupedWallets() {
    if (!this.wallets) return [];
    return this.wallets.filter((wallet) => !wallet.groupId);
  }

  @Watch('childWallets')
  childWalletsUpdate() {
    this.checkValid();
  }
}
