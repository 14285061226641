<template>
  <wallets-new v-if="isNew" @toggleUi="isNew = $event" :connections="connections"></wallets-new>
  <wallets-old v-else @toggleUi="isNew = $event"></wallets-old>
</template>

<script>
import gql from 'graphql-tag';

import { isAccountingProvider } from '@/utils/accountingProviders';

import WalletsNew from './WalletsNew.vue';
import WalletsOld from './WalletsOld.vue';

export default {
  components: {
    WalletsOld,
    WalletsNew,
  },
  data() {
    return {
      isNew: true,
      connections: [],
    };
  },
  mounted() {
    const storedVal = localStorage.getItem('walletsUi');
    this.isNew =
      storedVal === 'new' ? true : storedVal === 'old' ? false : this.checkFeatureFlag('new-wallets-ui', this.features);
  },
  apollo: {
    connections: {
      query: gql`
        query GetConnections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            provider
            lastSyncSEC
            isSetupComplete
            isDisabled
            isDeleted
            syncStatus {
              status
              lastSyncCompletedSEC
              errors
              warnings
              isRunning
            }
            name
            accountCode
            feeAccountCode
            connectionSpecificFields
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      update(data) {
        const ac = data.connections.filter((c) => !c.isDisabled && isAccountingProvider(c.provider));
        const manualContactExist = this.$store.getters['contacts/ENABLED_CONTACTS']?.some((c) => c.source === 'Manual');
        const shouldShowManualAccountingConnection =
          manualContactExist || this.$store.getters['categories/ENABLE_CATEGORIES']?.some((c) => c.source === 'Manual');

        if (shouldShowManualAccountingConnection) {
          const manualAccountingConnection = {
            id: 'Manual',
            provider: 'Bitwave',
            status: 'OK',
          };
          return [manualAccountingConnection].concat(ac);
        }

        return ac;
      },
    },
  },
};
</script>
