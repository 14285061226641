<template>
  <ba-dialog
    :show="dialog"
    v-on:show-dialog="dialog = true"
    :btnTooltip="canEnableForBulk ? 'Enable for Bulk Send' : 'Wallet is not connected'"
    btn-name="Enable for Bulk"
    title="Enable for Bulk Send"
    :disable-button="!canEnableForBulk"
  >
    <v-layout row wrap v-if="stage === 'start'">
      <v-flex xs12>
        <v-select :items="feeEstimates" item-value="value" v-model="fee" label="Select Fee" :disabled="deploying">
          <template slot="selection" slot-scope="data">
            {{ data.item.name }} ({{ data.item.value }} {{ data.item.feeUnit }})
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item.name }} ({{ data.item.value }} {{ data.item.feeUnit }})
          </template>
        </v-select>
      </v-flex>
      <v-flex xs4> Estimated Transaction Fees: {{ calculatedFees }} </v-flex>
      <v-flex xs8>
        <v-btn color="success" :disabled="!canDeploy" @click="deployEthMultiSendContract"
          >Deploy Multisend Contract</v-btn
        >
      </v-flex>
      <v-flex xs12 v-if="deploying">
        <v-progress-linear indeterminate color="green" />
      </v-flex>
    </v-layout>
    <v-layout row wrap v-if="stage === 'complete'">
      <v-flex xs12 class="text-xs-center">
        <h2>Contract Deployed!</h2>
        <h3>Save this information and double check the contract addresses when creating bulk sends.</h3>
      </v-flex>
      <v-flex offset-xs2 xs10> Deployment Transaction: {{ deployedTransactionHash }} </v-flex>
      <v-flex offset-xs2 xs10> Contract Address: {{ contractAddress }} </v-flex>
      <v-flex offset-xs2 xs10>
        <v-btn @click="close">Done</v-btn>
      </v-flex>
    </v-layout>
  </ba-dialog>
</template>

<script>
import gql from 'graphql-tag';
import Web3 from 'web3';

import { stringifyError } from '@/utils/error';

import { baConfig } from '../../../config';
import { BitwaveMultiSendContract } from '../../contracts/bitwaveMultisendContract';
import { MUT_SNACKBAR } from '../../store';

export default {
  props: ['wallet'],
  data() {
    return {
      dialog: false,
      isLoading: false,
      bulkPayment: {},
      deploying: false,
      feeEstimates: [],
      fee: '',
      calculatedFees: 'N/A',
      stage: 'start',
      deployedTransactionHash: null,
      contractAddress: null,
    };
  },
  apollo: {
    feeEstimates: {
      query: gql`
        query feeEstimates($coin: Coins!) {
          feeEstimates(coin: $coin) {
            id
            name
            feeUnit
            coin
            value
          }
        }
      `,
      loadingKey: 'isLoading',
      variables() {
        return {
          coin: 'ETH',
        };
      },
      skip() {
        const active = this.dialog;
        return !active;
      },
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit('refresh');
    },
    async deployContract() {
      try {
        const web3 = this.$store.state.connect.web3;
        if (!web3) {
          throw new Error('Unable to deploy, missing web3');
        }

        const useWallet = this.wallet;
        // const nonce = await web3.eth.getTransactionCount(fromAddress);
        const gasPrice = this.fee;
        // const gl = web3.utils.toHex(gasLimit);
        // const amt = web3.utils.toHex(0);
        const gasPriceInWei = web3.utils.toWei(gasPrice.toString(), 'gwei');
        const gp = web3.utils.toHex(gasPriceInWei);
        // const n = web3.utils.toHex(nonce);
        const contract = new web3.eth.Contract(BitwaveMultiSendContract.abi);
        const deployAction = contract.deploy({
          data: BitwaveMultiSendContract.bytecode,
          arguments: [],
        });

        const gasLimit = await deployAction.estimateGas({
          from: useWallet.address,
          gasPrice: gasPriceInWei,
        });

        return await new Promise((resolve, reject) => {
          deployAction
            .send({
              from: useWallet.address,
              gasPrice: gp,
              gas: gasLimit,
            })
            .on('receipt', (receipt) => {
              console.log(receipt.contractAddress); // contains the new contract address
              resolve({
                success: true,
                contractAddress: receipt.contractAddress,
                transactionId: receipt.transactionHash,
              });
            })
            .on('error', (error) => {
              reject(error);
            });
        });
      } catch (e) {
        this.$store.commit(MUT_SNACKBAR, {
          color: 'error',
          message: stringifyError(e),
        });
      }
    },
    async deployEthMultiSendContract() {
      if (!this.canDeploy) {
        this.$store.commit(MUT_SNACKBAR, {
          color: 'error',
          message: 'Unable to deploy with connected wallet',
        });
        return;
      }
      this.deploying = true;
      const res = await this.deployContract();
      this.deploying = false;
      if (res.success) {
        this.contractAddress = res.contractAddress;
        this.deployedTransactionHash = res.transactionId;

        const vars = {
          orgId: this.$store.state.currentOrg.id,
          walletId: this.wallet.id,
          multisendContractAddress: res.contractAddress,
        };

        const updated = await this.$apollo.mutate({
          mutation: gql`
            mutation ($orgId: ID!, $walletId: ID!, $multisendContractAddress: String) {
              updateWallet(orgId: $orgId, walletId: $walletId, multisendContractAddress: $multisendContractAddress) {
                id
              }
            }
          `,
          variables: vars,
        });
        if (updated.data && updated.data.updateWallet && updated.data.updateWallet.id) {
          this.stage = 'complete';
          this.$store.commit(MUT_SNACKBAR, {
            color: 'success',
            message: 'Accounting Setup Complete',
          });
        } else {
          this.$store.commit(MUT_SNACKBAR, {
            color: 'error',
            message:
              'Problem Updating System with Contract Address - please contact support with address: ' + res.error,
          });
        }
      } else {
        this.$store.commit(MUT_SNACKBAR, {
          color: 'error',
          message: 'Problem Deploying Contract: ' + res.error,
        });
      }
    },
    async calcFees() {
      if (this.$store.state.connect.web3) {
        const web3 = new Web3(baConfig.ethRpcUrl);
        if (this.fee) {
          const contract = new web3.eth.Contract(BitwaveMultiSendContract.abi);
          const deployAction = contract.deploy({
            data: BitwaveMultiSendContract.bytecode,
            arguments: [],
          });
          const gasEstimate = await deployAction.estimateGas();
          const gwei = gasEstimate * this.fee;
          const ethFees = web3.utils.fromWei(gwei.toString(), 'gwei');
          this.calculatedFees = ethFees;
        }
      } else {
        this.$store.commit(MUT_SNACKBAR, {
          color: 'error',
          message: 'Must connect a wallet to estimate fees.',
        });
      }
    },
    async checkForDeployedContract(transactionId) {
      const web3 = new Web3(baConfig.ethRpcUrl);

      const receipt = web3.eth.getTransactionReceipt(transactionId);
      if (receipt && receipt.contractAddress) {
        return true;
      }
    },
  },
  computed: {
    canDeploy() {
      return !this.deploying && this.fee;
    },
    canEnableForBulk() {
      const addresses = this.$store.getters['connect/ADDRESSES'];
      const networkId = this.$store.getters['connect/CONNECTED_NETWORK_ID'];
      const foundAddress = addresses.find((m) => m === this.wallet.address);

      const passAddress = foundAddress !== undefined;
      const passNetwork = networkId === 'eth';

      return passAddress && passNetwork;
    },
  },
  watch: {
    fee() {
      this.calcFees();
    },
  },
};
</script>
