

















































import gql from 'graphql-tag';
import * as _ from 'lodash';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import type { Wallet } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';

import UiFormLabel from '../../components/ui/UiFormLabel.vue';
import UiListItem from '../../components/ui/UiListItem.vue';
import UiTextEdit from '../../components/ui/UiTextEdit.vue';
import UiCheckbox from '../ui/UiCheckbox.vue';
import UiSelect from '../ui/UiSelect.vue';
import UiSelect2 from '../ui/UiSelect2.vue';
import { networks } from './constants';

@Component({
  components: {
    UiTextEdit,
    UiListItem,
    UiFormLabel,
    UiCheckbox,
    UiSelect,
    UiSelect2,
  },
  apollo: {
    wallets: {
      query: gql`
        query GetWallets($orgId: ID!) {
          wallets(orgId: $orgId) {
            id
            name
            description
            type
            deviceType
            address
            addresses
            path
            enabledCoins
            bulkSend {
              enabled
              canEnable
            }
            groupId
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
      errorPolicy: 'ignore',
    },
  },
})
export default class DefiOtherInput extends BaseVue {
  isSyncEnabled = false;
  networkId = '';
  networks = networks.map((x) => ({ value: x.value, label: x.text }));
  walletAddress = '';
  vaultAddress = '';
  tokenId = '';
  groupId = '';
  public wallets: Wallet[] = [];

  public get groupWallets() {
    return this.wallets?.filter((x) => x.type === 999);
  }

  public get isWalletAddressValid() {
    return this.walletAddress.trim().length > 0 && this.walletAddress.indexOf(' ') === -1;
  }

  public get isVaultAddressValid() {
    return this.vaultAddress.trim().length > 0 && this.vaultAddress.indexOf(' ') === -1;
  }

  public onDataChange() {
    this.$emit('data', {
      networkId: this.networkId,
      walletAddress: this.walletAddress,
      vaultAddress: this.vaultAddress,
      tokenId: this.tokenId,
      isSyncEnabled: this.isSyncEnabled,
      groupId: this.groupId,
    });
  }

  public get isValid() {
    return this.isWalletAddressValid && this.isVaultAddressValid;
  }

  @Watch('isValid')
  public validChanged() {
    this.$emit('valid', this.isValid);
  }

  mounted() {
    if (!this.networkId) this.networkId = this.networks[0].value;
  }
}
