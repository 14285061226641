





































































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiModal from '@/components/ui/UiModal.vue';
@Component({
  components: {
    UiButton,
    UiModal,
  },
})
export default class WalletAddedModal extends BaseVue {
  @Prop({ required: true })
  public readonly open!: boolean;

  @Prop()
  public readonly networkIcon?: string;

  @Prop()
  public readonly networkIconArray?: string[];

  public close() {
    this.$emit('close');
  }

  public addNewWallet() {
    this.$emit('close', 'reset');
  }

  public goToWalletList() {
    this.$emit('close', 'list');
  }
}
