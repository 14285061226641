import axios from 'axios';

import { UserUnauthenticatedException } from '@/exceptions/userUnauthenticatedException';

export class WalletService {
  private hasAccessToRoles: boolean;

  constructor(hasAccess: boolean) {
    this.hasAccessToRoles = hasAccess;
    // console.log(hasAccess, '>> wallet roles access <<');
  }

  /**
   *
   * @param orgId
   * @returns array of string
   */
  async getWalletRoles(orgId: string): Promise<{ id: string; name: string }[] | undefined> {
    // console.log({ orgId }, '>> req info <<', this.hasAccessToRoles);
    if (!this.hasAccessToRoles) return [];
    const url = `${process.env.VUE_APP_API_URL}orgs/${orgId}/walletRoles`;
    return await this.makeRequest(url);
  }

  async createRole(orgId: string, walletRoleName: string): Promise<string | null> {
    // console.log({ orgId, walletRole }, '>> req info <<', this.hasAccessToRoles);
    if (!this.hasAccessToRoles) return null;
    const url = `${process.env.VUE_APP_API_URL}orgs/${orgId}/walletRoles`;
    return await this.makeRequest(url, 'POST', { name: walletRoleName });
  }

  async deleteRole(orgId: string, walletRoleId: string): Promise<void> {
    // console.log({ orgId, walletRoleId }, '>> req info <<', this.hasAccessToRoles);
    if (!this.hasAccessToRoles) return;
    const url = `${process.env.VUE_APP_API_URL}orgs/${orgId}/walletRoles/${walletRoleId}`;
    return await axios.delete(url, { withCredentials: true, headers: { 'Content-Type': 'application/json' } });
  }

  private async makeRequest(url: string, method = 'GET', data?: object) {
    try {
      const resp = await axios.request({
        url,
        method,
        withCredentials: true,
        data,
      });

      if (resp) {
        if (resp.status === 200) {
          return resp.data ? resp.data : 'OK';
        } else {
          console.error('Axios request, got non 200 response: ' + resp.status, resp.data);
          if (resp.status === 401) {
            throw new UserUnauthenticatedException();
          }
        }
      }
      return undefined;
    } catch (e) {
      console.error('Error on reading response data', e);
      return undefined;
    }
  }
}
