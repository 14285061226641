


















import * as _ from 'lodash';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import type { IsValidAddress } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';

import { validWalletAddress } from '../../queries/walletQueries';
import UiSelect from '../ui/UiSelect.vue';
import UiTextEdit from '../ui/UiTextEdit.vue';

@Component({
  components: {
    UiTextEdit,
    UiSelect,
  },
})
export default class BlockchainAddressInput extends BaseVue {
  public xApiKey = '';
  public subAccountName = '';
  public exchangeContactId = '';
  public isValid = false;

  public debouncedValidate = _.debounce(this.validateForm.bind(this), 200);

  public async validateForm() {
    this.isValid = !!this.xApiKey && !!this.subAccountName && !!this.exchangeContactId;
  }

  public get contacts() {
    const contacts = this.$store.getters['contacts/ENABLED_CONTACTS'];
    const res: any = {};
    contacts.forEach((contact: any) => (res[contact.name] = contact.id));
    return res;
  }

  @Watch('xApiKey')
  @Watch('subAccountName')
  @Watch('exchangeContactId')
  public onWalletTypeChanged() {
    this.debouncedValidate();
    this.$emit('data', {
      createFoundry: {
        xApiKey: this.xApiKey,
        subAccountName: this.subAccountName,
        exchangeContactId: this.exchangeContactId,
      },
    });
    this.isValid = false;
  }

  @Watch('isValid')
  public validChanged() {
    this.$emit('valid', this.isValid);
  }
}
