<template>
  <span>
    <ba-dialog
      :show="dialog"
      v-on:show-dialog="dialog = true"
      small
      btnIcon="send"
      title="Send Form"
      btnTooltip="Send Bitcoins"
    >
      <v-container grid-list-md>
        <v-layout row wrap>
          <v-flex xs12>
            <v-select :items="enabledCoins" v-model="selectedCoin" label="Select Coin"></v-select>
          </v-flex>
          <v-flex xs12 v-if="isLoading">
            <v-progress-linear slot="progress" color="accent" height="2" indeterminate></v-progress-linear>
          </v-flex>
          <v-flex v-else>
            <template v-if="selectedCoin === 'BTC'">
              <v-flex xs12>
                <v-text-field
                  label="Address"
                  required
                  append-icon="fa-qrcode"
                  v-model="toAddress"
                  @click:append="qrClickHandler"
                  :messages="['Do not forget to double check the address']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field v-model="amount" label="Amount" required append-icon="fa-bitcoin"></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-select :items="feeEstimates" item-value="value" v-model="fee" label="Select Fee">
                  <template slot="selection" slot-scope="data">
                    {{ data.item.name }} ({{ data.item.value }} Sat / Byte)
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.name }} ({{ data.item.value }} Sat / Byte)
                  </template>
                </v-select>
              </v-flex>
            </template>
            <template v-if="selectedCoin === 'ETH' || selectedCoin === 'OXT'">
              <v-flex xs12>
                <v-text-field
                  label="Address"
                  required
                  append-icon="fa-qrcode"
                  v-model="toAddress"
                  @click:append="qrClickHandler"
                  :messages="['Do not forget to double check the address']"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field v-model="amount" label="Amount" required append-icon="fa-ethereum"></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field v-model="gasLimit" label="Gas Limit" required></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-select :items="feeEstimates" item-value="value" v-model="fee" label="Select Fee">
                  <template slot="selection" slot-scope="data">
                    {{ data.item.name }} ({{ data.item.value }} {{ data.item.feeUnit }})
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.name }} ({{ data.item.value }} {{ data.item.feeUnit }})
                  </template>
                </v-select>
              </v-flex>
            </template>
          </v-flex>

          <!-- TODO ADd this back -->
          <!--<v-flex xs12>-->
          <!--<v-text-field label="Description" required v-model="description"></v-text-field>-->
          <!--</v-flex>-->
          <!--<v-flex xs12>-->
          <!--&lt;!&ndash;<v-select&ndash;&gt;-->
          <!--&lt;!&ndash;:items="categories"&ndash;&gt;-->
          <!--&lt;!&ndash;label="Category"&ndash;&gt;-->
          <!--&lt;!&ndash;v-model="category"&ndash;&gt;-->
          <!--&lt;!&ndash;:hint="category?'':'Select a category'"&ndash;&gt;-->
          <!--&lt;!&ndash;item-text="name"&ndash;&gt;-->
          <!--&lt;!&ndash;item-value="id"&ndash;&gt;-->
          <!--&lt;!&ndash;persistent-hint&ndash;&gt;-->
          <!--&lt;!&ndash;return-object&ndash;&gt;-->
          <!--&lt;!&ndash;&gt;</v-select>&ndash;&gt;-->
          <!--<v-autocomplete-->
          <!--:items="categories"-->
          <!--v-model="category"-->
          <!--label="Category"-->
          <!--item-text="name"-->
          <!--item-value="id"-->
          <!--:hint="category?'':'Select a category'"-->
          <!--persistent-hint-->
          <!--return-object />-->
          <!--</v-flex>-->
          <!--<v-flex xs12>-->
          <!--<v-autocomplete-->
          <!--v-model="contact"-->
          <!--:items="contacts"-->
          <!--label="Contact"-->
          <!--item-text="name"-->
          <!--item-value="id" />-->
          <!--</v-flex>-->
        </v-layout>
      </v-container>
      <v-flex>
        <h4>Transaction Fees: {{ calculatedFees }}</h4>
      </v-flex>
      <v-flex xs6 class="text-xs-right">
        <v-btn :loading="sending" :disabled="sending || !validToSend" @click="sendCoins()" color="primary">
          Send
        </v-btn>
      </v-flex>
      <v-flex xs12 v-if="sending">
        <v-progress-linear indeterminate color="green" />
      </v-flex>
    </ba-dialog>
  </span>
</template>

<script>
import gql from 'graphql-tag';
import Web3 from 'web3';

import { baConfig } from '../../config';
import { orchidAbi, orchidAddress } from '../contracts/orchid';
import { sendTrezorERC20Token, simpleSendTrezorETH } from '../utils/hardwareDevices';
// import * as _ from "underscore";

export default {
  props: ['wallet', 'walletId'], //, "enabledCoins"],
  data: () => ({
    sending: false,
    amount: '',
    toAddress: '',
    dialog: false,
    description: '',
    category: '',
    categories: [],
    contacts: [],
    contact: null,
    selectedCoin: '',
    fee: '',
    isLoading: false,
    feeEstimates: [],
    gasLimit: 21000,
    calculatedFees: 'N/A',
  }),
  computed: {
    enabledCoins() {
      if (this.wallet && this.wallet.balance) {
        const coins = this.wallet.balance.balances.map((m) => m.coin);
        const enabled = coins.filter((m) => m === 'BTC' || m === 'ETH' || m === 'OXT');
        return enabled;
      } else {
        return [];
      }
    },
    unit() {
      if (this.selectedCoin === 'BTC') {
        return 'Bitcoin';
      } else if (this.selectedCoin === 'ETH') {
        return 'Ethereum';
      } else {
        throw new Error('Unsupported');
      }
    },
    validToSend() {
      if (this.toAddress && this.amount && this.fee) {
        // Check balance
        return true;
      }
      return false;
    },
    // selectedFee() {
    //   const f = _.find(this.feeEstimates, m => m.id === this.fee);
    //   return f.value;
    // }
  },
  methods: {
    resetForm() {
      this.toAddress = '';
      this.amount = '';
    },
    async calcFees() {
      const web3 = new Web3(baConfig.ethRpcUrl);
      if (this.toAddress && this.amount && this.fee) {
        const amount = web3.utils.toWei(this.amount, 'ether');
        const to = this.toAddress;

        if (this.selectedCoin === 'ETH') {
          const gasEstimate = await web3.eth.estimateGas({
            from: to,
            to,
            amount,
          });
          const gwei = gasEstimate * this.fee;
          const ethFees = web3.utils.fromWei(gwei.toString(), 'gwei');
          this.calculatedFees = ethFees;
        } else if (this.selectedCoin === 'OXT') {
          // const amount = web3.utils.toWei(this.amount, "ether");
          // const to = this.toAddress;

          const abi = orchidAbi;
          const oaddress = orchidAddress;

          const orchid = new web3.eth.Contract(abi, oaddress, {
            from: this.wallet.address,
            gas: 100000,
          });
          const action = orchid.methods.transfer(to, amount);
          const gasEstimate = await action.estimateGas();
          const gwei = gasEstimate * this.fee;
          const ethFees = web3.utils.fromWei(gwei.toString(), 'gwei');
          this.calculatedFees = ethFees;
        }
      } else {
        return 'N/A';
      }
    },
    async getFees() {
      this.isLoading = true;
      let feeCoin = this.selectedCoin;
      if (feeCoin === 'OXT') {
        feeCoin = 'ETH';
      }

      const vars = {
        coin: feeCoin,
      };

      const res = await this.$apollo.query({
        query: gql`
          query feeEstimates($coin: Coins!) {
            feeEstimates(coin: $coin) {
              id
              feeUnit
              name
              coin
              value
            }
          }
        `,
        // Parameters
        variables: vars,
        fetchPolicy: 'network-only',
      });
      this.isLoading = false;
      this.feeEstimates = res.data.feeEstimates;
    },
    qrClickHandler() {
      console.log('clicked QR icon');
    },
    async sendCoins() {
      this.sending = true;
      if (this.wallet.type === 2) {
        await this.createEnterpriseTransaction();
      } else {
        await this.sendHardwareTransaction();
      }
    },
    async sendHardwareTransaction() {
      if (this.wallet.deviceType === 'Trezor') {
        let res;
        if (this.selectedCoin === 'ETH') {
          res = await simpleSendTrezorETH(
            this.wallet.path,
            this.amount,
            this.wallet.address,
            this.toAddress,
            this.gasLimit,
            this.fee
          );
        } else if (this.selectedCoin === 'OXT') {
          res = await sendTrezorERC20Token(
            orchidAbi,
            orchidAddress,
            this.wallet.path,
            this.amount,
            this.wallet.address,
            this.toAddress,
            this.gasLimit,
            this.fee
          );
        }

        if (res.success) {
          this.showSuccessSnackbar('Transaction Sent: ' + res.transactionId);
          this.resetForm();
          this.dialog = false;
        } else {
          this.showErrorSnackbar('Failed to send transaction. Error: ' + res.error);
        }
      } else {
        throw new Error('Unsupported hardware wallet');
      }
    },
    createEnterpriseTransaction() {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        walletId: this.walletId,
        coin: this.selectedCoin,
        create: {
          addressSpends: {
            address: this.toAddress,
            amount: this.amount,
            coin: this.selectedCoin,
            unit: this.unit,
          },
          fee: this.selectedFee,
        },
      };

      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($orgId: ID!, $walletId: ID!, $coin: Coins!, $create: CreateTransactionInput!) {
              createTransaction(orgId: $orgId, walletId: $walletId, coin: $coin, create: $create) {
                id
              }
            }
          `,
          // Parameters
          variables: vars,
        })
        .then(() => {
          this.dialog = false;
        });
    },
  },
  watch: {
    selectedCoin(newValue) {
      if (newValue === 'OXT') {
        this.gasLimit = 300000;
      } else {
        this.gasLimit = 21000;
      }

      this.getFees();
    },
    amount() {
      this.calcFees();
    },
    toAddress() {
      this.calcFees();
    },
    fee() {
      this.calcFees();
    },
  },
};
</script>
