










































import gql from 'graphql-tag';
import * as _ from 'lodash';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import type { Wallet } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';

import UiFormLabel from '../../components/ui/UiFormLabel.vue';
import UiListItem from '../../components/ui/UiListItem.vue';
import UiTextEdit from '../../components/ui/UiTextEdit.vue';
import { validWalletAddress } from '../../queries/walletQueries';

@Component({
  apollo: {
    wallets: {
      query: gql`
        query GetWallets($orgId: ID!) {
          wallets(orgId: $orgId) {
            id
            name
            description
            type
            deviceType
            address
            addresses
            path
            enabledCoins
            bulkSend {
              enabled
              canEnable
            }
            groupId
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
      errorPolicy: 'ignore',
    },
  },
  components: {
    UiTextEdit,
    UiListItem,
    UiFormLabel,
  },
})
export default class GroupOtherInput extends BaseVue {
  wallets: Wallet[] = [];
  childWallets: Wallet[] = [];
  walletSearch = '';
  showList = false;
  public description = '';
  public isValid = false;

  get nonGroupedWallets() {
    if (!this.wallets) return [];
    return this.wallets.filter((wallet) => !wallet.groupId && !this.childWallets.find((item) => wallet.id === item.id));
  }

  public addChildWallet(wallet: Wallet) {
    this.childWallets.push(wallet);
    this.$emit('data', { childWallets: this.childWallets.map((x) => x.id) });
    this.checkIsValid();
  }

  public removeChildWallet(wallet: Wallet) {
    this.childWallets = this.childWallets.filter((item) => item.id !== wallet.id);
    this.$emit('data', { childWallets: this.childWallets.map((x) => x.id) });
    this.checkIsValid();
  }

  public checkIsValid() {
    this.isValid = this.childWallets.length > 0;
  }

  @Watch('isValid')
  public validChanged() {
    this.$emit('valid', this.isValid);
  }
}
