















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

export interface Item {
  value: string | number;
  icon: string;
  label: string;
  description: string;
}

@Component({})
export default class UiSelectPro extends Vue {
  @Prop()
  public readonly value?: string | number;

  @Prop()
  public readonly label?: string;

  @Prop({ required: true })
  public readonly items!: Item[];

  @Prop()
  public readonly disabled?: boolean;

  public showMenu = false;
  private blurTimerId?: ReturnType<typeof setTimeout>;
  public selectedValue: string | number = '';

  get currentItem() {
    if (!this.selectedValue) {
      return null;
    }
    return this.items.find((item) => item.value === this.selectedValue);
  }

  public async onMenuToggle() {
    this.showMenu = !this.showMenu;

    if (this.showMenu) {
      await this.$nextTick();
      const dropdownRef = this.$refs.dropdown as HTMLElement;
      dropdownRef?.focus();
    }
  }

  public onSelect(item: Item) {
    this.selectedValue = item.value;
    this.showMenu = false;
    this.$emit('input', item.value);
  }

  public onBlur() {
    if (this.blurTimerId) {
      return;
    }
    this.blurTimerId = setTimeout(() => {
      if (this.blurTimerId) {
        this.showMenu = false;
        clearTimeout(this.blurTimerId);
        this.blurTimerId = undefined;
      }
    }, 200);
  }

  @Watch('value')
  onValueChanged(value: number | string | undefined) {
    this.selectedValue = value ?? '';
  }
}
