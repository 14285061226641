


































import { debounce } from 'lodash';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';

import UiCheckbox from '../../components/ui/UiCheckbox.vue';
import UiSelect from '../../components/ui/UiSelect.vue';
import UiTextEdit from '../../components/ui/UiTextEdit.vue';

@Component({
  components: {
    UiTextEdit,
    UiSelect,
    UiCheckbox,
  },
})
export default class BlockchainAddressInput extends BaseVue {
  public user = '';
  public password = '';
  public accountId = '';
  public exchangeContactId = '';
  public useJwt = false;
  public jwt = '';
  public isValid = false;

  public debouncedValidate = debounce(this.validateForm.bind(this), 1000);

  public async validateForm() {
    this.isValid = Boolean(
      ((this.useJwt === false && this.user && this.password) || (this.useJwt && this.jwt)) &&
        this.accountId &&
        this.exchangeContactId
    );
  }

  public get contacts() {
    const contacts = this.$store.getters['contacts/ENABLED_CONTACTS'];
    const res: any = {};
    contacts.forEach((contact: any) => (res[contact.name] = contact.id));
    return res;
  }

  @Watch('user')
  @Watch('password')
  @Watch('accountId')
  @Watch('exchangeContactId')
  @Watch('jwt')
  public onWalletTypeChanged() {
    this.debouncedValidate();
    this.$emit('data', {
      createPrimeTrust: {
        user: this.user,
        password: this.password,
        accountId: this.accountId,
        exchangeContactId: this.exchangeContactId,
        jwt: this.jwt,
      },
    });
  }

  @Watch('isValid')
  public validChanged() {
    this.$emit('valid', this.isValid);
  }
}
