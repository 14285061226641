

















































import 'firebase/firestore';

import gql from 'graphql-tag';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import type { IsValidAddress, Wallet, Watch } from '@/api-svc-types';
import { Coins } from '@/api-svc-types';

import { BaseVue } from '../../BaseVue';
import { validWalletAddress } from '../../queries/walletQueries';
import { WatchWalletGroup } from './watchWalletsEnum';

@Component({})
export default class WatchWallet extends BaseVue {
  @Prop({ required: true })
  public readonly details!: Wallet;

  @Prop({ required: true })
  public readonly walletType!: WatchWalletGroup;

  @Prop()
  public subsidiaryId?: string;

  @Prop()
  public walletRoleId?: string;

  public coins = ['BTC', 'ETH', 'EOS', 'DASH'];
  public newType = '';
  public newHD = '';
  public inputAddress = '';
  public addressList: Record<string, boolean> = {};
  // public newNetwork = '';
  public snackbarText = '';
  public snackbarColor = '';
  public snackbar = false;
  public isValidAddresses = false;
  public isLoading = false;
  public saveStatusText = '';
  public savingWallet = false;

  private newAddress?: string;

  public get addressTypes() {
    if (this.walletType === WatchWalletGroup.BTC || this.walletType === WatchWalletGroup.DASH) {
      return [
        {
          name: 'Simple',
          id: 'Simple',
        },
        {
          name: 'Hierarchical Deterministic (HD, XPUB, etc.)',
          id: 'XPUB',
        },
      ];
    } else {
      return [
        {
          name: 'Simple',
          id: 'Simple',
        },
      ];
    }
  }

  public get isValid() {
    if (!this.details.name || this.details.name.length === 0) {
      return false;
    }

    // const validCoin = this.coins.find((m) => m === this.newNetwork);
    // if (validCoin === undefined) {
    //   return false;
    // }

    if (this.newType === '') {
      return false;
    }

    if (this.newType === 'Simple') {
      const valid = this.inputAddress !== '' && this.inputAddress.length > 11 && this.isValidAddresses;
      return valid;
    } else if (this.newType === 'XPUB') {
      return this.newHD !== '' && this.newHD.indexOf(' ') === -1;
    }

    return false;
  }

  public save() {
    const orgId = this.$store.state.currentOrg.id;
    const name = this.details.name;
    this.savingWallet = true;

    // TODO extract magic string / numbers below
    const prems = [
      {
        userId: this.$store.state.user?.id,
        role: 4,
      },
    ];

    const wallet = {
      name,
      type: 'watch',
      subsidiaryId: this.subsidiaryId,
      walletRoleId: this.walletRoleId,
      watch: null as Watch | null,
    };

    let coinValue: Coins;
    switch (this.walletType) {
      case WatchWalletGroup.BTC:
        coinValue = Coins.Btc;
        break;
      case WatchWalletGroup.DASH:
        coinValue = Coins.Dash;
        break;
      case WatchWalletGroup.EOS:
        coinValue = Coins.Eos;
        break;
      case WatchWalletGroup.ETH:
        coinValue = Coins.Eth;
        break;
    }

    const watch: Partial<Watch> = {
      coin: coinValue,
      type: undefined as string | undefined,
      addresses: undefined as string[] | undefined,
      derivationKey: undefined as string | undefined,
    };

    if (this.newType === 'Simple') {
      watch.type = 'addressSet';
      watch.addresses = [this.inputAddress];
    } else if (this.newType === 'XPUB') {
      watch.type = 'hd';
      watch.derivationKey = this.newHD;
    } else {
      throw new Error('unsupported');
    }

    wallet.watch = watch as Watch;

    const vars = {
      wallet,
      orgId,
      prems,
    };
    this.isLoading = true;
    this.saveStatusText = 'Saving wallet...';
    this.$apollo
      .mutate({
        // Query
        mutation: gql`
          mutation ($orgId: ID!, $wallet: WalletInput!, $prems: [WalletPermissionInput]!) {
            createWallet(orgId: $orgId, wallet: $wallet, prems: $prems) {
              id
            }
          }
        `,
        // Parameters
        variables: vars,
      })
      .then(() => {
        console.log('added wallet');
        this.resetWallet();
        this.$root.$emit('refresh-wallets');
        this.$emit('wallet-saved');
      })
      .catch((err) => {
        const arrayError = err.graphQLErrors.map(
          (e: Record<string, string>) => `${e.message} ${e.traceId ? ` - Error ID: ${e.traceId}` : ''}`
        );
        this.snackbar = true;
        this.snackbarText = 'Error creating wallet: ' + arrayError.join(' \n');
        this.snackbarColor = 'error';
        console.log(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public resetWallet() {
    this.details.addresses = [];
    this.details.name = '';
    this.newType = '';
    this.newAddress = '';
    // this.newNetwork = '';
  }

  public async validAddress(address: string): Promise<boolean | null | undefined> {
    const variables = {
      networkId: this.walletType.toString().toLowerCase(),
      address,
    };
    const query = await validWalletAddress;
    const response = (await this.$apollo.query({
      query,
      variables,
    })) as { data?: { isAddressValid?: IsValidAddress | null } | null };
    return response?.data?.isAddressValid?.isValid;
  }

  private keyMonitorDebounceTimer?: ReturnType<typeof setTimeout>;
  private keyMonitorLoadingCount = 0;

  public async keyMonitor() {
    this.isLoading = true;
    this.saveStatusText = 'Validating input...';

    if (this.keyMonitorDebounceTimer !== undefined) {
      clearTimeout(this.keyMonitorDebounceTimer);
    }

    if (this.inputAddress.length <= 10 || this.inputAddress.indexOf(' ') >= 0) {
      this.isValidAddresses = false;
      this.addressList = {};
      this.isLoading = false;
      return;
    }

    this.keyMonitorDebounceTimer = setTimeout(async () => {
      this.keyMonitorDebounceTimer = undefined;

      if (this.inputAddress.length > 10 && this.newType === 'Simple') {
        this.keyMonitorLoadingCount++;
        try {
          this.isValidAddresses = (await this.validAddress(this.inputAddress)) ?? false;
        } catch (e) {
          this.isValidAddresses = false;
          throw e;
        } finally {
          this.keyMonitorLoadingCount--;
          if (this.keyMonitorLoadingCount === 0) {
            this.isLoading = false;
          }
        }
      }
    }, 1000);
  }
}
