<template>
  <div>
    <ba-dialog
      :show="dialog"
      v-on:show-dialog="dialog = true"
      btnColor="success"
      :btnName="$t('_createWallet')"
      :btnTooltip="$t('_createWallet')"
      :title="$t('_createWallet')"
      :onHide="hideDialog"
    >
      <i class="fa fa-spinner tw-pr-5 tw-items-center" v-if="isLoading"></i>
      <v-container fluid grid-list-xl v-else>
        <v-layout wrap align-center>
          <v-flex xs12 d-flex>
            <v-select
              label="Subsidiary"
              :items="subs"
              v-model="walletSubsidiary"
              hint="Subsidiary"
              item-text="name"
              item-value="id"
              v-if="checkFeatureFlag('subsidiaries')"
            ></v-select>
          </v-flex>
          <v-flex v-if="checkFeatureFlag('soda-report')" xs12 d-flex>
            <ui-select-2
              :items="walletRoles"
              :label="$tc('_walletRole')"
              style="max-width: 300px"
              keyField="id"
              labelField="name"
              searchable
              upsert
              keyOnly
              :value="selectedWalletRoleId"
              v-model="walletRoleId"
              @enterKey="addWalletRole"
            >
            </ui-select-2>
            <!-- <v-select
              taggable
              :items="walletRoles"
              item-value="id"
              item-text="name"
              :label="$tc('_walletRole')"
              v-model="walletRoleId"
            ></v-select> -->
          </v-flex>
          <v-flex xs12 d-flex>
            <v-select
              :items="enabledWalletTypes"
              :label="$t('_walletType')"
              v-model="walletType"
              :hint="`${walletType ? walletType.description : ''}`"
              item-text="name"
              item-value="id"
              persistent-hint
              return-object
            ></v-select>
          </v-flex>
          <watch-wallet
            v-if="
              walletType.id === 'btcWatch' ||
              walletType.id === 'dashWatch' ||
              walletType.id === 'eosWatch' ||
              walletType.id === 'ethWatch'
            "
            :wallet-type="walletType.id"
            :details="watchWalletDetails"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
            v-on:wallet-saved="walletSaved"
            type="watch"
          />
          <enterprise-hot
            v-if="walletType.id === 'eh'"
            v-on:wallet-saved="walletSaved"
            :details="ehDetails"
            :wallet-role-id="walletRoleId"
          />
          <live-wallet-dialog
            t
            v-if="walletType.id === 'hardware'"
            v-on:wallet-saved="walletSaved"
            :details="hwDetails"
            :wallet-role-id="walletRoleId"
          />
          <multisig-watch-wallet
            v-if="walletType.id === 'multisig'"
            v-on:wallet-saved="walletSaved"
            :details="multisigDetails"
            :wallet-role-id="walletRoleId"
          />
          <manual-wallet
            v-if="walletType.id === 'manual'"
            v-on:wallet-saved="walletSaved"
            :details="manualDetails"
            wallet-type="manual"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <loopring-marketplace-wallet
            v-if="walletType.id === 'lrc'"
            v-on:wallet-saved="walletSaved"
            :details="lrcDetails"
            wallet-type="lrc-marketplace"
            network-id="lrc"
          />
          <address-wallet
            v-if="walletType.id === 'celo'"
            v-on:wallet-saved="walletSaved"
            :details="celoDetails"
            wallet-type="celo"
            type="celo"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'celoAbbs'"
            v-on:wallet-saved="walletSaved"
            :details="celoDetails"
            network-id="celo"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'cosmos'"
            v-on:wallet-saved="walletSaved"
            :details="cosmosDetails"
            network-id="cosmos"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <address-wallet
            v-if="walletType.id === 'polygon'"
            v-on:wallet-saved="walletSaved"
            :details="polygonDetails"
            wallet-type="polygon"
            type="polygon"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <address-wallet
            v-if="walletType.id === 'stx'"
            v-on:wallet-saved="walletSaved"
            :details="stxDetails"
            wallet-type="stx"
            type="stx"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <address-wallet
            v-if="walletType.id === 'bsc'"
            v-on:wallet-saved="walletSaved"
            :details="bscDetails"
            wallet-type="bsc"
            type="bsc"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <address-wallet
            v-if="walletType.id === 'avalanche'"
            v-on:wallet-saved="walletSaved"
            :details="avalancheDetails"
            wallet-type="avalanche"
            type="avalanche"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <!-- <address-wallet
            v-if="walletType.id === 'heco'"
            v-on:wallet-saved="walletSaved"
            :details="hecoDetails"
            wallet-type="heco"
            type="heco"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          /> -->
          <account-based-blockchain-wallet
            v-if="walletType.id === 'mina'"
            v-on:wallet-saved="walletSaved"
            :details="minaDetails"
            network-id="mina"
            type="mina"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'eth'"
            v-on:wallet-saved="walletSaved"
            :details="ethDetails"
            network-id="eth"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'solana'"
            v-on:wallet-saved="walletSaved"
            :details="solanaDetails"
            network-id="sol"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'beacon'"
            v-on:wallet-saved="walletSaved"
            :details="beaconDetails"
            network-id="beacon"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'aptos'"
            v-on:wallet-saved="walletSaved"
            :details="aptosDetails"
            network-id="apt"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'aurora'"
            v-on:wallet-saved="walletSaved"
            :details="auroraDetails"
            network-id="aurora"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'base'"
            v-on:wallet-saved="walletSaved"
            :details="baseDetails"
            network-id="base"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'terra'"
            v-on:wallet-saved="walletSaved"
            :details="terraDetails"
            network-id="terra"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'oasis'"
            v-on:wallet-saved="walletSaved"
            :details="oasisDetails"
            network-id="rose"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'filecoin'"
            v-on:wallet-saved="walletSaved"
            :details="filecoinDetails"
            network-id="fil"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'flow'"
            v-on:wallet-saved="walletSaved"
            :details="flowDetails"
            network-id="flow"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'casper'"
            v-on:wallet-saved="walletSaved"
            :details="casperDetails"
            network-id="casper"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'immutable'"
            v-on:wallet-saved="walletSaved"
            :details="immutableDetails"
            network-id="immutable"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'klaytn'"
            v-on:wallet-saved="walletSaved"
            :details="klaytnDetails"
            network-id="klay"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'kusama'"
            v-on:wallet-saved="walletSaved"
            :details="kusamaDetails"
            network-id="ksm"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'near'"
            v-on:wallet-saved="walletSaved"
            :details="nearDetails"
            network-id="near"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'bch'"
            v-on:wallet-saved="walletSaved"
            :details="bchDetails"
            network-id="bch"
            :subsidiary-id="walletSubsidiary"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'zcash'"
            v-on:wallet-saved="walletSaved"
            :details="zcashDetails"
            network-id="zec"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'hbar'"
            v-on:wallet-saved="walletSaved"
            :details="hederaDetails"
            network-id="hbar"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'polkadot'"
            v-on:wallet-saved="walletSaved"
            :details="polkadotDetails"
            network-id="dot"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'polygonAbbs'"
            v-on:wallet-saved="walletSaved"
            :details="polygonDetails"
            network-id="polygon"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'bscAbbs'"
            v-on:wallet-saved="walletSaved"
            :details="bscDetails"
            network-id="bsc"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'avalancheAbbs'"
            v-on:wallet-saved="walletSaved"
            :details="avalancheDetails"
            network-id="avaxc"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'arbitrum'"
            v-on:wallet-saved="walletSaved"
            :details="arbitrumDetails"
            network-id="arb"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'btc'"
            v-on:wallet-saved="walletSaved"
            :details="btcDetails"
            network-id="btc"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'ltc'"
            v-on:wallet-saved="walletSaved"
            :details="ltcDetails"
            network-id="ltc"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'dash'"
            v-on:wallet-saved="walletSaved"
            :details="dashDetails"
            network-id="dash"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'doge'"
            v-on:wallet-saved="walletSaved"
            :details="dogeDetails"
            network-id="doge"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'kava'"
            v-on:wallet-saved="walletSaved"
            :details="kavaDetails"
            network-id="kava"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'optimism'"
            v-on:wallet-saved="walletSaved"
            :details="optimismDetails"
            network-id="op"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'osmosis'"
            v-on:wallet-saved="walletSaved"
            :details="osmosisDetails"
            network-id="osmo"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'xrp'"
            v-on:wallet-saved="walletSaved"
            :details="xrpDetails"
            network-id="xrp"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'ftm'"
            v-on:wallet-saved="walletSaved"
            :details="ftmDetails"
            network-id="ftm"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'gnosis'"
            v-on:wallet-saved="walletSaved"
            :details="gnosisDetails"
            network-id="gnosis"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'xlm'"
            v-on:wallet-saved="walletSaved"
            :details="stellarDetails"
            network-id="xlm"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'canton'"
            v-on:wallet-saved="walletSaved"
            :details="cantonDetails"
            network-id="canton"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <account-based-blockchain-wallet
            v-if="walletType.id === 'zeta'"
            v-on:wallet-saved="walletSaved"
            :details="zetaDetails"
            network-id="zeta"
            :subsidiary-id="walletSubsidiary"
            :wallet-role-id="walletRoleId"
          />
          <group-wallet v-if="walletType.id === 'group'" :details="groupDetails" v-on:wallet-saved="walletSaved" />
          <defi-wallet
            v-if="walletType.id === 'defi'"
            v-on:wallet-saved="walletSaved"
            :details="defiDetails"
            :wallet-role-id="walletRoleId"
            wallet-type="defi"
          />

          <v-flex xs12 v-if="walletNotes">
            <span>Wallet Note:</span><br />
            <span class="tw-text-sm tw-text-neutral-300"> * {{ walletNotes }} </span>
          </v-flex>
        </v-layout>
      </v-container>
      <v-divider></v-divider>
    </ba-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AccountBasedBlockchainWallet from '@/components/wallets/AccountBasedBlockchainWallet';
import AddressWallet from '@/components/wallets/AddressWallet';
import LiveWalletDialog from '@/components/wallets/LiveWalletDialog';
import { WalletService } from '@/services/walletService';

import { baConfig } from '../../config';
import { OrganizationsApi } from '../../generated/api-svc';
// ui components
import UiSelect2 from '../components/ui/UiSelect2.vue';
import EnterpriseHot from '../components/wallets/EnterpriseHot.vue';
import WatchWallet from '../components/wallets/WatchWallet.vue';
import DefiWallet from './wallets/DefiWallet';
import GroupWallet from './wallets/GroupWallet';
import LoopringMarketplaceWallet from './wallets/LoopringMarketplaceWallet';
import ManualWallet from './wallets/ManualWallet';
import MultisigWatchWallet from './wallets/MultisigWatchWallet';

export default {
  components: {
    LoopringMarketplaceWallet,
    AccountBasedBlockchainWallet,
    AddressWallet,
    LiveWalletDialog,
    WatchWallet,
    EnterpriseHot,
    MultisigWatchWallet,
    ManualWallet,
    GroupWallet,
    DefiWallet,
    UiSelect2,
  },
  data() {
    return {
      // walletTypes: ['Enterprise - Hot', 'Enterprise - Cold', 'Enterprise - Custodial', 'Petty Cash', 'Watch'],
      isLoading: false,
      walletType: '',
      walletSubsidiary: null,
      subs: [],
      dialog: false,
      watchWalletDetails: {
        isValid: false,
      },
      ehDetails: {
        isValid: false,
        usersAndRoles: [],
      },
      hwDetails: {
        isValid: false,
        path: '',
        deviceName: '',
      },
      multisigDetails: {
        isValid: false,
      },
      manualDetails: {
        isValid: false,
      },
      celoDetails: {
        isValid: false,
      },
      cosmosDetails: {
        isValid: false,
      },
      polygonDetails: {
        isValid: false,
      },
      polkadotDetails: {
        isValid: false,
      },
      optimismDetails: {
        isValid: false,
      },
      osmosisDetails: {
        isValid: false,
      },
      avalancheDetails: {
        isValid: false,
      },
      solanaDetails: {
        isValid: false,
      },
      stellarDetails: {
        isValid: false,
      },
      cantonDetails: {
        isValid: false,
      },
      zetaDetails: {
        isValid: false,
      },
      auroraDetails: {
        isValid: false,
      },
      baseDetails: {
        isValid: false,
      },
      aptosDetails: {
        isValid: false,
      },
      minaDetails: {
        isValid: false,
      },
      bscDetails: {
        isValid: false,
      },
      // hecoDetails: {
      //   isValid: false,
      // },
      ethDetails: {
        isValid: false,
      },
      terraDetails: {
        isValid: false,
      },
      oasisDetails: {
        isValid: false,
      },
      lrcDetails: {
        isValid: false,
      },
      dashDetails: {
        isValid: false,
      },
      zcashDetails: {
        isValid: false,
      },
      ftmDetails: {
        isValid: false,
      },
      gnosisDetails: {
        isValid: false,
      },
      beaconDetails: {
        isValid: false,
      },
      filecoinDetails: {
        isValid: false,
      },
      flowDetails: {
        isValid: false,
      },
      casperDetails: {
        isValid: false,
      },
      stxDetails: {
        isValid: false,
      },
      immutableDetails: {
        isValid: false,
      },
      klaytnDetails: {
        isValid: false,
      },
      kusamaDetails: {
        isValid: false,
      },
      nearDetails: {
        isValid: false,
      },
      bchDetails: {
        isValid: false,
      },
      hederaDetails: {
        isValid: false,
      },
      arbitrumDetails: {
        isValid: false,
      },
      btcDetails: {
        isValid: false,
      },
      ltcDetails: {
        isValid: false,
      },
      dogeDetails: {
        isValid: false,
      },
      kavaDetails: {
        isValid: false,
      },
      xrpDetails: {
        isValid: false,
      },
      groupDetails: {
        isValid: false,
      },
      defiDetails: {
        isValid: false,
      },
      walletRoleId: null,
      selectedWalletRoleId: null,
      walletRoles: [],
    };
  },
  computed: {
    ...mapGetters({
      features: 'features',
    }),
    enabledWalletTypes() {
      const walletTypes = [
        {
          name: this.$t('_aptosWallet'),
          id: 'aptos',
          description: this.$t('_aptosWalletDescription'),
          enabled: this.checkFeatureFlag('aptos', this.features),
        },
        {
          name: this.$t('_arbitrumWallet'),
          id: 'arbitrum',
          description: this.$t('_arbitrumWalletDescription'),
        },
        {
          name: this.$t('_auroraWallet'),
          id: 'aurora',
          description: this.$t('_auroraWalletDescription'),
          enabled: this.checkFeatureFlag('aurora', this.features),
        },
        // DEPRICATED v1 syncer that used snowtrace
        // {
        //   name: this.$t('_avalancheWallet'),
        //   id: 'avalanche',
        //   description: this.$t('_avalancheWalletDescription'),
        //   enabled: !this.checkFeatureFlag('avalancheABBS', this.features),
        // },
        {
          name: this.$t('_avalancheWallet'),
          id: 'avalancheAbbs',
          description: this.$t('_avalancheWalletDescription'),
        },
        {
          name: this.$t('_baseWallet'),
          id: 'base',
          description: this.$t('_baseWalletDescription'),
        },
        {
          name: this.$t('_bchWallet'),
          id: 'bch',
          description: this.$t('_bchWalletDescription'),
          enabled: this.checkFeatureFlag('bch', this.features),
        },
        {
          name: this.$t('_beaconWallet'),
          id: 'beacon',
          description: this.$t('_beaconWalletDescription'),
          enabled: this.checkFeatureFlag('beacon', this.features),
        },
        {
          name: this.$t('_btcWallet'),
          id: 'btcWatch',
          description: this.$t('_btcWalletDescription'),
          enabled: !this.checkFeatureFlag('btc', this.features),
        },
        {
          name: this.$t('_btcWallet'),
          id: 'btc',
          description: this.$t('_btcWalletDescription'),
          enabled: this.checkFeatureFlag('btc', this.features),
        },
        {
          name: this.$t('_bscWallet'),
          id: 'bscAbbs',
          description: this.$t('_bscWalletDescription'),
        },
        {
          name: this.$t('_casperWallet'),
          id: 'casper',
          description: this.$t('_casperWalletDescription'),
        },
        {
          name: this.$t('_celoWallet'),
          id: 'celo',
          description: this.$t('_celoWalletDescription'),
          enabled: !this.checkFeatureFlag('celoABBS', this.features),
        },
        {
          name: this.$t('_celoWallet'),
          id: 'celoAbbs',
          description: this.$t('_celoWalletDescription'),
          enabled: this.checkFeatureFlag('celoABBS', this.features),
        },
        {
          name: this.$t('_cosmosWallet'),
          id: 'cosmos',
          description: this.$t('_cosmosWalletDescription'),
          enabled: this.checkFeatureFlag('cosmos', this.features),
        },
        {
          name: this.$t('_dashWallet'),
          id: 'dash',
          description: this.$t('_dashWalletDescription'),
          enabled: !this.checkFeatureFlag('dash-watch', this.features),
        },
        {
          name: this.$t('_dashWallet'),
          id: 'dashWatch',
          description: this.$t('_dashWalletDescription'),
          enabled: this.checkFeatureFlag('dash-watch', this.features),
        },
        {
          name: this.$t('_defiWallet'),
          id: 'defi',
          description: this.$t('_defiWalletDescription'),
          enabled: this.checkFeatureFlag('advanced-defi-wallet-creation', this.features),
        },
        {
          name: this.$t('_dogeWallet'),
          id: 'doge',
          description: this.$t('_dogeWalletDescription'),
          enabled: this.checkFeatureFlag('doge', this.features),
        },
        {
          name: this.$t('_enterpriseWallet'),
          id: 'eh',
          description: this.$t('_enterpriseWalletDescription'),
        },
        {
          name: this.$t('_eosWallet'),
          id: 'eosWatch',
          description: this.$t('_eosWalletDescription'),
        },
        {
          name: this.$t('_ethWallet'),
          id: 'ethWatch',
          description: this.$t('_ethWalletDescription'),
          enabled: this.checkFeatureFlag('eth-watch', this.features),
        },
        {
          name: this.$t('_ethRollupWallet'),
          id: 'eth',
          description: this.$t('_ethRollupWalletDescription'),
          enabled: !this.checkFeatureFlag('eth-watch', this.features),
        },
        {
          name: this.$t('_ftmWallet'),
          id: 'ftm',
          description: this.$t('_ftmWalletDescription'),
          enabled: this.checkFeatureFlag('ftm', this.features),
        },
        {
          name: this.$t('_filecoinWallet'),
          id: 'filecoin',
          description: this.$t('_filecoinWalletDescription'),
        },
        {
          name: this.$t('_flowWallet'),
          id: 'flow',
          description: this.$t('_flowWalletDescription'),
        },
        {
          name: this.$t('_gnosisWallet'),
          id: 'gnosis',
          description: this.$t('_gnosisWalletDescription'),
        },
        {
          name: this.$t('_groupWallet'),
          id: 'group',
          description: this.$t('_groupWalletDescription'),
        },
        {
          name: this.$t('_hardwareWallet'),
          id: 'hardware',
          description: this.$t('_hardwareWalletDescription'),
        },
        // {
        //   name: this.$t('_hecoWallet'),
        //   id: 'heco',
        //   description: this.$t('_hecoWalletDescription'),
        // },
        {
          name: this.$t('_hederaWallet'),
          id: 'hbar',
          description: this.$t('_hederaWalletDescription'),
        },
        {
          name: this.$t('_immutableWallet'),
          id: 'immutable',
          description: this.$t('_immutableWalletDescription'),
          enabled: this.checkFeatureFlag('immutable', this.features),
        },
        {
          name: this.$t('_kavaWallet'),
          id: 'kava',
          description: this.$t('_kavaWalletDescription'),
          enabled: this.checkFeatureFlag('kava', this.features),
        },
        {
          name: this.$t('_klaytnWallet'),
          id: 'klaytn',
          description: this.$t('_klaytnWalletDescription'),
          enabled: this.checkFeatureFlag('klay', this.features),
        },
        {
          name: this.$t('_kusamaWallet'),
          id: 'kusama',
          description: this.$t('_kusamaWalletDescription'),
          enabled: this.checkFeatureFlag('kusama', this.features),
        },
        {
          name: this.$t('_lrcWallet'),
          id: 'lrc',
          description: this.$t('_lrcWalletDescription'),
          enabled: this.checkFeatureFlag('loopring', this.features),
        },
        {
          name: this.$t('_ltcWallet'),
          id: 'ltc',
          description: this.$t('_ltcWalletDescription'),
          enabled: this.checkFeatureFlag('ltc', this.features),
        },
        {
          name: this.$t('_manualWallet'),
          id: 'manual',
          description: this.$t('_manualWalletDescription'),
        },
        {
          name: this.$t('_minaWallet'),
          id: 'mina',
          description: this.$t('_minaWalletDescription'),
        },
        {
          name: this.$t('_multisigWallet'),
          id: 'multisig',
          description: this.$t('_multisigWalletDescription'),
        },
        {
          name: this.$t('_nearWallet'),
          id: 'near',
          description: this.$t('_nearWalletDescription'),
          enabled: this.checkFeatureFlag('near', this.features),
        },
        {
          name: this.$t('_oasisWallet'),
          id: 'oasis',
          description: this.$t('_oasisWalletDescription'),
        },
        {
          name: this.$t('_optimismWallet'),
          id: 'optimism',
          description: this.$t('_optimismWalletDescription'),
        },
        {
          name: this.$t('_osmosisWallet'),
          id: 'osmosis',
          description: this.$t('_osmosisWalletDescription'),
          enabled: this.checkFeatureFlag('osmo', this.features),
        },
        {
          name: this.$t('_polkadotWallet'),
          id: 'polkadot',
          description: this.$t('_polkadotWalletDescription'),
          enabled: this.checkFeatureFlag('polkadot', this.features),
        },
        {
          name: this.$t('_polygonWallet'),
          id: 'polygon',
          description: this.$t('_polygonWalletDescription'),
          enabled: this.checkFeatureFlag('polygonOrig', this.features),
        },
        {
          name: this.$t('_polygonWallet'),
          id: 'polygonAbbs',
          description: this.$t('_polygonWalletDescription'),
          enabled: !this.checkFeatureFlag('polygonOrig', this.features),
        },
        {
          name: this.$t('_solanaWallet'),
          id: 'solana',
          description: this.$t('_solanaWalletDescription'),
        },
        {
          name: this.$t('_stxWallet'),
          id: 'stx',
          description: this.$t('_stxWalletDescription'),
        },
        {
          name: this.$t('_stellarWallet'),
          id: 'xlm',
          description: this.$t('_stellarWalletDescription'),
          enabled: this.checkFeatureFlag('xlm', this.features),
        },
        {
          name: this.$t('_cantonWallet'),
          id: 'canton',
          description: this.$t('_cantonWalletDescription'),
          // enabled: this.checkFeatureFlag('canton', this.features),
        },
        {
          name: this.$t('_zetaWallet'),
          id: 'zeta',
          description: this.$t('_zetaWalletDescription'),
          enabled: this.checkFeatureFlag('zeta', this.features),
          notes: 'Please ensure that when you add this wallet you add it using Zeta Address, not the EVM address.',
        },
        {
          name: this.$t('_terraWallet'),
          id: 'terra',
          description: this.$t('_terraWalletDescription'),
        },
        {
          name: this.$t('_xrpWallet'),
          id: 'xrp',
          description: this.$t('_xrpWalletDescription'),
        },
        {
          name: this.$t('_zcashWallet'),
          id: 'zcash',
          description: this.$t('_zcashWalletDescription'),
          enabled: this.checkFeatureFlag('zcash', this.features),
        },
      ]
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((m) => m.enabled === undefined || m.enabled === true);
      return walletTypes.filter((m) => m.enabled === undefined || m.enabled === true);
    },
    canSave() {
      var canSave = false;
      if (this.watchWalletDetails.isValid && this.walletType.id === 'watch') {
        canSave = true;
      } else if (this.ehDetails.isValid && this.walletType.id === 'eh') {
        canSave = true;
      }
      return canSave;
      // return this.watchWalletDetails.isValid; // TODO fix logic
    },
    walletNotes() {
      return this.walletType?.notes;
    },
  },
  async mounted() {
    this.isLoading = true;
    try {
      // load wallet roles
      await this.getWalletRoles();
    } catch (e) {
      this.showErrorSnackbar('failed to load wallet roles');
    }
    if (this.checkFeatureFlag('subsidiaries', this.features)) {
      try {
        const orgsApi = new OrganizationsApi(undefined, baConfig.getFriendlyApiUrl());
        const resp = await orgsApi.listSubsidiaries(this.orgId, { withCredentials: true });
        if (resp.status === 200) {
          this.subs = resp.data;
        } else {
          throw new Error('Problem loading subs');
        }
      } catch (e) {
        this.showErrorSnackbar('failed to load subsidiaries');
      }
    }

    this.isLoading = false;
  },
  methods: {
    walletSaved() {
      this.$store.dispatch('wallets/getWallets', this.$store.state.currentOrg.id);
      this.hideDialog();
    },
    hideDialog() {
      this.reset();
      this.dialog = false;
    },
    reset() {
      this.walletType = '';
      this.watchWalletDetails.isValid = false;
      this.ehDetails.isValid = false;
      this.ehDetails.usersAndRoles = [];
      this.hwDetails.isValid = false;
      this.hwDetails.path = '';
      this.hwDetails.deviceName = '';
      this.multisigDetails.isValid = false;
      this.manualDetails.isValid = false;
      this.celoDetails.isValid = false;
      this.cosmosDetails.isValid = false;
      this.polkadotDetails.isValid = false;
      this.stellarDetails.isValid = false;
      this.cantonDetails.isValid = false;
      this.zetaDetails.isValid = false;
      this.polygonDetails.isValid = false;
      this.optimismDetails.isValid = false;
      this.osmosisDetails.isValid = false;
      this.bscDetails.isValid = false;
      this.avalancheDetails.isValid = false;
      // this.hecoDetails.isValid = false;
      this.solanaDetails.isValid = false;
      this.beaconDetails.isValid = false;
      this.ethDetails.isValid = false;
      this.lrcDetails.isValid = false;
      this.ftmDetails.isValid = false;
      this.filecoinDetails.isValid = false;
      this.flowDetails.isValid = false;
      this.gnosisDetails.isValid = false;
      this.casperDetails.isValid = false;
      this.stxDetails.isValid = false;
      this.immutableDetails.isValid = false;
      this.klaytnDetails.isValid = false;
      this.kusamaDetails.isValid = false;
      this.groupDetails.isValid = false;
      this.aptosDetails.isValid = false;
      this.auroraDetails.isValid = false;
      this.baseDetails.isValid = false;
      this.hederaDetails.isValid = false;
      this.arbitrumDetails.isValid = false;
      this.btcDetails.isValid = false;
      this.ltcDetails.isValid = false;
      this.dogeDetails.isValid = false;
      this.kavaDetails.isValid = false;
      this.xrpDetails.isValid = false;
      this.dashDetails.isValid = false;
      this.bchDetails.isValid = false;
    },
    async getWalletRoles() {
      const orgId = this.$store.state.currentOrg.id;
      // init wallet service
      const walletSvc = new WalletService(this.checkFeatureFlag('soda-report'));
      this.walletRoles = await walletSvc.getWalletRoles(orgId);
    },
    async addWalletRole(walletRoleName) {
      if (!walletRoleName) return;
      const orgId = this.$store.state.currentOrg.id;
      const walletSvc = new WalletService(this.checkFeatureFlag('soda-report'));
      const walletRoleId = await walletSvc.createRole(orgId, walletRoleName);
      if (walletRoleId) {
        this.walletRoles = [
          ...this.walletRoles,
          {
            id: walletRoleId,
            name: walletRoleName,
          },
        ];

        // default selected wallet role id
        this.selectedWalletRoleId = walletRoleId;
        // this.walletRoleId = walletRoleId;
      }
    },
  },
};
</script>
