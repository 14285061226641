





















import * as _ from 'lodash';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import type { IsValidAddress } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';

import UiSelect from '../../components/ui/UiSelect.vue';
import UiTextEdit from '../../components/ui/UiTextEdit.vue';
import { validWalletAddress } from '../../queries/walletQueries';

@Component({
  components: {
    UiTextEdit,
    UiSelect,
  },
})
export default class BlockchainAddressInput extends BaseVue {
  public apiKey = '';
  public passphrase = '';
  public signingKey = '';
  public exchangeContactId = '';
  public isValid = false;

  public debouncedValidate = _.debounce(this.validateForm.bind(this), 200);

  public async validateForm() {
    this.isValid = !!this.apiKey && !!this.passphrase && !!this.signingKey && !!this.exchangeContactId;
  }

  public get contacts() {
    const contacts = this.$store.getters['contacts/ENABLED_CONTACTS'];
    const res: any = {};
    contacts.forEach((contact: any) => (res[contact.name] = contact.id));
    return res;
  }

  @Watch('apiKey')
  @Watch('passphrase')
  @Watch('signingKey')
  @Watch('exchangeContactId')
  public onWalletTypeChanged() {
    this.debouncedValidate();
    this.$emit('data', {
      createCoinbasePrime: {
        apiKey: this.apiKey,
        passphrase: this.passphrase,
        signingKey: this.signingKey,
        exchangeContactId: this.exchangeContactId,
      },
    });
    this.isValid = false;
  }

  @Watch('isValid')
  public validChanged() {
    this.$emit('valid', this.isValid);
  }
}
