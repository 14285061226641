






























import { debounce } from 'lodash';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';

import UiSelect from '../../components/ui/UiSelect.vue';
import UiTextEdit from '../../components/ui/UiTextEdit.vue';

@Component({
  components: {
    UiTextEdit,
    UiSelect,
  },
})
export default class BlockchainAddressInput extends BaseVue {
  public clientId = '';
  public clientSecret = '';
  public scope = '';
  public itBitUserId = '';
  public itBitKey = '';
  public itBitSecret = '';
  public exchangeContactId = '';
  public isValid = false;

  public debouncedValidate = debounce(this.validateForm.bind(this), 1000);

  public async validateForm() {
    this.isValid = !!this.clientId && !!this.clientSecret && !!this.scope && !!this.exchangeContactId;
  }

  public get contacts() {
    const contacts = this.$store.getters['contacts/ENABLED_CONTACTS'];
    const res: any = {};
    contacts.forEach((contact: any) => (res[contact.name] = contact.id));
    return res;
  }

  @Watch('clientId')
  @Watch('clientSecret')
  @Watch('scope')
  @Watch('itBitUserId')
  @Watch('itBitKey')
  @Watch('itBitSecret')
  @Watch('exchangeContactId')
  public onWalletTypeChanged() {
    this.debouncedValidate();
    this.$emit('data', {
      createPaxos: {
        clientId: this.clientId,
        clientSecret: this.clientSecret,
        scope: this.scope,
        itBitUserId: this.itBitUserId,
        itBitKey: this.itBitKey,
        itBitSecret: this.itBitSecret,
        exchangeContactId: this.exchangeContactId,
      },
    });
  }

  @Watch('isValid')
  public validChanged() {
    this.$emit('valid', this.isValid);
  }
}
