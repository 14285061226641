













































































































































import globalAxios from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { baConfig } from '../../../config';
import { ConnectionsApi } from '../../../generated/api-svc';
import { AccountValidationResponse } from './accountValidationResponse';

@Component({})
export default class ValidationSummary extends Vue {
  public validationData: AccountValidationResponse | null = null;

  @Prop({ required: true })
  public readonly walletType!: string;

  @Prop()
  public readonly tokenType?: string;

  @Prop()
  public readonly connectionName?: string;

  @Prop({ required: true })
  public readonly data: any;

  public isLoading = false;

  public show = false;

  public async validate(args: any) {
    this.show = true;
    this.isLoading = true;
    try {
      const connectionsApi = new ConnectionsApi(undefined, baConfig.getFriendlyApiUrl());
      const resp = await connectionsApi.validateConnection(this.$store.state.currentOrg.id, args, {
        withCredentials: true,
      });
      if (resp.status === 200) {
        this.validationData = resp.data;
      } else {
        this.validationData = {
          success: false,
          error: 'Problem Validating: ' + resp.status + ' ' + (resp.data as any).message,
        };
      }
    } catch (e: any) {
      this.validationData = {
        success: false,
        error: 'Problem Validating: ' + e.message,
      };
    } finally {
      this.isLoading = false;
    }

    return this.validationData;
  }

  public get contactName() {
    const contacts = this.$store.getters['contacts/ENABLED_CONTACTS'];
    const data: any = Object.values(this.data)?.[0];
    if (!data?.exchangeContactId) return '';
    const contact = contacts.find((contact: any) => contact.id === data.exchangeContactId);
    return contact?.name ?? '';
  }

  public reset() {
    this.isLoading = false;
    this.show = false;
    this.validationData = null;
  }
}
