<template>
  <span>
    <ba-dialog
      :dialog="dialog"
      v-on:show-dialog="dialog = true"
      small
      btnIcon="call_received"
      btnTooltip="Receive Coins"
      :title="'Receive - ' + wallet.name"
    >
      <v-select :items="enabledCoins" v-model="selectedCoin" label="Select Coin"></v-select>
      <sub v-if="!isLoading && !receiveAddress">Please select a coin</sub>
      <template v-else-if="!isLoading && receiveAddress">
        <strong>{{ receiveAddress }}</strong>
        <br /><sub>Remember, only use this address for {{ selectedCoin }}</sub>
      </template>

      <spinner-newton-cradle v-else-if="isLoading" size="sm" />
      <v-divider></v-divider>
    </ba-dialog>
  </span>
</template>

<script>
import gql from 'graphql-tag';
export default {
  props: ['wallet', 'walletId', 'enabledCoins'],
  data() {
    return {
      dialog: false,
      addresses: [],
      selectedCoin: '',
      isLoading: false,
      receiveAddress: '',
    };
  },
  methods: {},
  watch: {
    selectedCoin(value) {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        walletId: this.walletId,
        coin: value,
      };

      this.$apollo
        .query({
          // Query
          query: gql`
            query nextReceiveAddress($orgId: ID!, $walletId: ID!, $coin: Coins!) {
              nextReceiveAddress(orgId: $orgId, walletId: $walletId, coin: $coin)
            }
          `,
          // Parameters
          variables: vars,
        })
        .then((m) => {
          this.receiveAddress = m.data.nextReceiveAddress;
        });
    },
  },
};
</script>
